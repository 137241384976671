import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { GetAllUserActivities, GetUserList } from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const UserActivities = () => {
  const navigate = useNavigate();
  const { currentColor, userID, userToken } = useStateContext();
  const [AllUserActs, setAllUserActs] = useState([]);
  const [getusers, setusers] = useState([]);
  const [user, setuser] = useState(0);
  const { promiseInProgress } = usePromiseTracker();

  const ActsGrid = [
    {
      field: "name",
      headerText: "Activity",
      minWidth: "180",
      width: "180",
      maxWidth: "280",
      textAlign: "left",
    },
    {
      field: "datetime",
      headerText: "DateTime",
      minWidth: "180",
      width: "180",
      maxWidth: "240",
      textAlign: "center",
    },
    {
      field: "note",
      headerText: "Activity Note",
      minWidth: "360",
      width: "440",
      maxWidth: "650",
      textAlign: "left",
    },
  ];

  const handleBackClick = async (event) => {
    event.preventDefault();
    try {
      navigate("/Users");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeVendor = (e) => {
    setuser(e.target.value);
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        await GetUserList(userID, userToken)
          .then((resp) => {
            setusers(resp.data || []);
            setuser(resp.data[0].user_id);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  useEffect(() => {
    async function fetchData() {
      if (user !== 0 && user && userID && userToken) {
        trackPromise(
          Promise.all([GetAllUserActivities(user, userID, userToken)])
            .then((result) => {
              setAllUserActs(result[0].data);
            })
            .catch((err) => {
              console.log(err.message);
            })
        );
      }
    }
    fetchData();
  }, [user, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Admin" title="USER ACTIVITIES" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          <Col md="auto" style={{ padding: "0" }}>
            <select
              className="select-custom rounded-xl"
              value={user}
              onChange={handleChangeVendor}
            >
              {/* <option value={0}>{"Select User"}</option> */}
              {getusers.map((item) => (
                <option key={item.user_id} value={item.user_id}>
                  {item.user}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
      {promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <GridComponent
          className="custom-grid1"
          dataSource={AllUserActs}
          allowPaging={true}
          pageSettings={{ pageSize: 24 }}
          allowSorting
          allowResizing
          toolbar={["Search"]}
          // rowSelected={handleRowSelected}
          rowHeight={18}
        >
          <ColumnsDirective>
            {ActsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
          />
        </GridComponent>
      )}
      <div className="flex justify-center">
        <Button
          margin="7px"
          color="white"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </div>
    </div>
  );
};

export default UserActivities;
