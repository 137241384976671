import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  //GetAllSalesByID,
  //
  GetReceiveLogPurchaseOrderByID,
  GetReceiveProductsByPO_ID,
  EditReceive_Log,
} from "../../api";
//import Select from "react-select";
import { Header } from "../../components";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
// import NumberFormat from 'react-number-format/NumberFormat';
import { useStateContext } from "../../contexts/ContextProvider";

const ReceiveLog = () => {
  let param = useParams();
  const so_id_param = param.po_id;
  //const gridRef = useRef(null);
  const [AllAccounts, setAllAccounts] = useState("");
  const [AllAccounts1, setAllAccounts1] = useState("");
  const [so_id, setso_id] = useState("");
  const { currentColor, userID, userToken } = useStateContext();
  const navigate = useNavigate();
  const [store_id, setstore_id] = useState(0);
  //const [getstores, setstores] = useState([]);
  // const [ship_date, setship_date] = useState(
  //   new Date().toISOString().split("T")[0] + " 00:00:00"
  // );
  const [mfg_date, setmfg_date] = useState(null);
  const [acc_notes, setAccNotes] = useState("");
  const [qty_shipped, setqty_shipped] = useState(0);
  const [qty_reject, setqty_reject] = useState(0);
  const [recv_by, setrecv_by] = useState("");

  const [so_date, setso_date] = useState("");
  const [row_id, setrow_id] = useState("");
  const [invoice, setinvoice] = useState("");
  const [customer, setcustomer] = useState("");
  const [last_qty_edit, setlast_qty_edit] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [prod_code, setprod_code] = useState("");
  const [sDatetime, setSDatetime] = useState("");
  const [sMinDatetime, setSMinDatetime] = useState("");
  const [sMaxDatetime, setSMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);

  const [formData] = useState({
    ship_date: new Date().toISOString().split("T")[0],
    // mfg_date: new Date().toISOString().split("T")[0],
  });

  // useEffect(() => {
  //   if (gridRef.current && row_id === null) {
  //     // Clear the selection when row_id is null
  //     gridRef.current.clearSelection();
  //   }
  // }, [row_id]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

  const [formData1, setFormData1] = useState({
    po_id: so_id_param,
    store_id: store_id,
    receive_logs: [],
  });

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSDatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setSMaxDatetime(now.toISOString().slice(0, 19));
      // now = new Date(now.getFullYear(), 0, 2);
      // setSMinDatetime(now.toISOString().slice(0, 19));
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeSDateTime = (e) => {
    if (e === "undefined") {
      setSDatetime(null);
    } else {
      if (new Date(e) > new Date(sMinDatetime)) {
        setSDatetime(e);
      }
    }
  };

  const handleChangeAccNote = (e) => {
    setAccNotes(e.target.value);
  };

  const handleChangeRecv_by = (e) => {
    setrecv_by(e.target.value);
  };

  const handleChangeqty_shipped = (e) => {
    setqty_shipped(e.target.value);
  };

  const handleChangeqty_reject = (e) => {
    setqty_reject(e.target.value);
  };

  const customersGrid = [
    {
      field: "item_no",
      headerText: "#",
      width: "65",
      textAlign: "right",
    },
    {
      field: "code",
      headerText: "Product Code",
      width: "135",
      textAlign: "Center",
    },
    {
      headerText: "Product",
      field: "product",
      width: "200",
      textAlign: "left",
    },
    // {
    //   field: "details",
    //   headerText: "Description",
    //   width: "320",
    //   textAlign: "Center",
    // },
    {
      field: "total_qty",
      headerText: "Qty",
      width: "80",
      textAlign: "right",
    },
    {
      field: "qty_recv",
      headerText: "Qty Rcv to Date",
      width: "150",
      textAlign: "right",
    },
    {
      field: "qty_reject",
      headerText: "Qty Rej to Date",
      width: "145",
      textAlign: "right",
    },
    {
      field: "last_qty_recv",
      headerText: "Last Qty Rcv",
      width: "130",
      textAlign: "right",
    },
    {
      field: "last_qty_reject",
      headerText: "Last Qty Rej",
      width: "130",
      textAlign: "right",
    },
    {
      headerText: "Item Note",
      field: "item_note",
      width: "200",
      textAlign: "left",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      setIsEditMode(false);
      setso_id("");
      setIsUpdateButtonDisabled(true);
      setForceUpdate(false);
      // const code = [...AllAccounts];
      setprod_code(row_id);
      setrow_id(null);
      // console.log(row_id);
      // console.log("Back");
      // navigate("/sales");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("Back");
      navigate("/Purchase");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      setIsUpdateButtonDisabled(false);
      // setIsEditMode(false);
      setForceUpdate(true);
      setIsEditMode(false);
      // setIsUpdateButtonDisabled(false);
      setrow_id(null);
      setso_id(null);
      setqty_shipped(0);
      setAccNotes("");
      setmfg_date(null);
      setrecv_by("");
      setqty_reject(0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateItemClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (!sDatetime || sDatetime === "") {
        alert("Please select Shipping DateTime!");
        return;
      }
      if (
        (parseFloat(qty_shipped) > 0 && parseFloat(qty_reject) >= 0) ||
        (parseFloat(qty_shipped) >= 0 && parseFloat(qty_reject) > 0)
      ) {
        const updatedItem = [...AllAccounts];
        const updatedItem1 = [...AllAccounts1];
        //console.log(last_qty_edit);
        var number = parseFloat(updatedItem[prod_code].qty_recv);
        var number1 = parseFloat(updatedItem[prod_code].qty_reject);
        var qty = number + parseFloat(qty_shipped);
        var qty1 = number1 + parseFloat(qty_reject);
        var k =
          parseFloat(qty_reject) +
          parseFloat(updatedItem[prod_code].qty_reject);
        const last_qty_edits = [...last_qty_edit];
        var s = qty - parseFloat(last_qty_edits[prod_code].qty_recv);
        var rj = qty1 - parseFloat(last_qty_edits[prod_code].qty_reject);

        var s1 = qty - parseFloat(updatedItem[prod_code].qty_recv);
        var rj1 = qty1 - parseFloat(updatedItem[prod_code].qty_reject);

        if (qty <= updatedItem[prod_code].total_qty) {
          var Sdt = null;
          if (dTNow) {
            var d = new Date();
            Sdt =
              [
                d.getFullYear(),
                (d.getMonth() + 1).toString().padStart(2, "0"),
                d.getDate().toString().padStart(2, "0"),
              ].join("-") +
              " " +
              [
                d.getHours().toString().padStart(2, "0"),
                d.getMinutes().toString().padStart(2, "0"),
                d.getSeconds().toString().padStart(2, "0"),
              ].join(":");
          } else {
            Sdt = sDatetime.replace("T", " ");
          }
          const newitem = {
            item_no: updatedItem[prod_code].item_no,
            code: updatedItem[prod_code].code,
            details: updatedItem[prod_code].details,
            product: updatedItem[prod_code].product,
            total_qty: updatedItem[prod_code].total_qty,
            qty_recv: qty,
            recv_date: Sdt,
            qty_reject: k,
            mfg_date: mfg_date,
            recv_by: recv_by,
            note: acc_notes,
            last_qty_recv: s,
            last_qty_reject: rj,
            product_id: updatedItem[prod_code].product_id,
          };
          const updatedRecords = updatedItem.filter(
            (record) => record.item_no !== updatedItem[prod_code].item_no
          );
          //console.log(updatedItem);
          //console.log(updatedRecords);
          //console.log(newitem);
          // if (parseFloat(qty_shipped) > 0 && parseFloat(qty_reject) >= 0) {
          setAllAccounts([...updatedRecords, newitem]);
          newitem.last_qty_recv = s1;
          newitem.last_qty_reject = rj1;
          setAllAccounts1([...updatedItem1, newitem]);
          // }
          // if (parseFloat(qty_shipped) >= 0 && parseFloat(qty_reject) > 0) {
          //   setAllAccounts([...updatedRecords, newitem]);
          //   setAllAccounts1([...updatedItem1, newitem]);
          // }
          // if(!row_id){
          setForceUpdate(true);
          setIsEditMode(false);
          setIsUpdateButtonDisabled(false);
          setrow_id("");
          setso_id(null);
          // }
          setqty_shipped(0);
          setAccNotes("");
          setmfg_date(null);
          setrecv_by("");
          setqty_reject(0);
        } else {
          alert("Qty Received cannot be greater than Ordered Qty!");
        }
      } else {
        alert("New Qty Received or Qty Reject should be greater than ZERO!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeMfgDate = (e) => {
    // setFormData({
    //   ...formData,
    //   [e.target.name]: e.target.value,
    // });

    if (mfg_date === "undefined") {
      setmfg_date(null);
    } else {
      setmfg_date(e.target.value + " 00:00:00");
      // console.log(mfg_date);
    }
  };

  const handleViewEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      const updatedFormData = { ...formData1 };
      updatedFormData.po_id = so_id_param;
      updatedFormData.store_id = store_id;
      updatedFormData.receive_logs = [];

      AllAccounts1.forEach((product, index) => {
        const shipment = {
          item_no: product.item_no,
          recv_status_id: 73,
          quantity: product.total_qty,
          product_id: product.product_id,
          recv_by: product.recv_by,
          recv_date: product.recv_date,
          quantity_recv: product.last_qty_recv,
          quantity_reject: product.last_qty_reject,
          mfg_date: product.mfg_date,
          note: product.note,
        };
        updatedFormData.receive_logs.push(shipment);
      });

      setFormData1(updatedFormData);
      // console.log(updatedFormData);
      const response = await EditReceive_Log(
        updatedFormData,
        userID,
        userToken
      );
      if (response.status === 200) {
        alert("Receive Log Updated Successfully");
      } else {
        alert("Receive Log Failed to Update");
      }

      navigate("/Purchase");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleChangeStore = (e) => {
  //   setstore(e.target.value);
  //   const s_id = getstores.find((item) => item.name === e.target.value);
  //   setstore_id(s_id.store_id);
  // };

  const onChangeGrid = (args) => {
    // const updatedItem = [...AllAccounts];
    // console.log("hehheh")
    // setAllAccounts(updatedItem);
    // setForceUpdate(false);
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    setso_id("");
    const selectedRowData = args.data;
    setso_id(selectedRowData ? selectedRowData.code : "");
    // if(isUpdateButtonDisabled){
    //   setIsEditMode(true)
    // }
    // setprod_code(args.rowIndex);
    // console.log(selectedRowData.code);
    // console.log(args.rowIndex);
    setrow_id(args.rowIndex);
  };

  useEffect(() => {
    if (so_id_param && userID && userToken) {
      TimeoutUtility.resetTimeout();
      var now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      setSDatetime(now.toISOString().slice(0, 19));
      GetReceiveLogPurchaseOrderByID(so_id_param, userID, userToken)
        .then((resp) => {
          setcustomer(resp.data[0].vendor);
          setso_date(resp.data[0].order_date);
          setinvoice(resp.data[0].vendor_invoice_no);
          setstore_id(resp.data[0].store_id);
          var sal_dt = new Date(resp.data[0].o_date);
          sal_dt.setMinutes(sal_dt.getMinutes() - sal_dt.getTimezoneOffset());
          setSMinDatetime(sal_dt.toISOString().slice(0, 19));
          //console.log(resp.data);
        })
        .catch((err) => {
          console.log(err.message);
        });

      GetReceiveProductsByPO_ID(so_id_param, userID, userToken)
        .then((result) => {
          setlast_qty_edit(result.data || []);
          setAllAccounts(result.data || []);
          // setlast_qty_edit(result.data.last_qty || []);
          //console.log(result.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [so_id_param, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="RECEIVE LOG" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            //className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">PO #: </label>
                  <input
                    className="input"
                    required
                    type="text"
                    name="name"
                    value={so_id_param}
                    placeholder="Purchase Order"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Supplier: </label>
                  <input
                    type="text"
                    name="email"
                    value={customer}
                    placeholder="Vendor Name"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">PO Date: </label>
                  <br />
                  <input
                    required
                    type="text"
                    name="phone"
                    value={so_date}
                    placeholder="PO Date"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Supplier Invoice: </label>
                  <br />
                  <input
                    type="text"
                    name="invoice"
                    value={invoice}
                    placeholder="Invoice No."
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <div className="flex justify-end">
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      (isUpdateButtonDisabled && !isEditMode) ||
                      !so_id ||
                      isEditMode
                        ? "grey"
                        : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  onClick={handleAddEmployeesClick}
                  disabled={
                    (isUpdateButtonDisabled && !isEditMode) ||
                    !so_id ||
                    isEditMode
                  }
                >
                  Edit / Rcv
                </button>
              </div>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <GridComponent
                dataSource={AllAccounts}
                allowPaging={true}
                pageSettings={{ pageSize: 16 }}
                allowSorting
                //toolbar={["Search"]}
                allowResizing
                rowSelected={handleRowSelected}
                rowHeight={36}
                className="custom-grid"
                onChange={onChangeGrid}
                disabled={!row_id}
              >
                <ColumnsDirective>
                  {customersGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Page,
                    Toolbar,
                    Selection,
                    Edit,
                    Sort,
                    Filter,
                  ]}
                />
              </GridComponent>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <div className="flex justify-end">
                <button
                  style={{
                    padding: "10px",
                    backgroundColor: !isUpdateButtonDisabled
                      ? "grey"
                      : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  disabled={!isUpdateButtonDisabled}
                  onClick={handleUpdateItemClick}
                >
                  Update Item
                </button>

                <button
                  style={{
                    padding: "10px",
                    backgroundColor: !isUpdateButtonDisabled
                      ? "grey"
                      : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  disabled={!isUpdateButtonDisabled}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
          <br />
          <Row
            xs={1}
            sm={1}
            //className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Recv'd By: </label>
                  <input
                    required
                    type="text"
                    name="recv_by"
                    value={recv_by}
                    placeholder="Receive By"
                    className="input"
                    onChange={handleChangeRecv_by}
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">
                    <span>Receive DateTime:</span>
                    <span style={{ float: "right", paddingRight: "22%" }}>
                      Now{" "}
                      <input
                        type="checkbox"
                        checked={dTNow === 1}
                        disabled={!isUpdateButtonDisabled}
                        onChange={handleChangeDTNow}
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </span>
                  </label>
                  <div className="flex mb-2">
                    <input
                      disabled={dTNow || !isUpdateButtonDisabled}
                      className="input"
                      id="ship_date"
                      type="datetime-local"
                      style={{
                        background:
                          (dTNow || !isUpdateButtonDisabled) && "lightgray",
                      }}
                      value={sDatetime}
                      min={sMinDatetime}
                      max={sMaxDatetime}
                      onChange={(e) => handleChangeSDateTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">New Qty Recv'd: </label>
                  <input
                    required
                    type="number"
                    name="qty_shipped"
                    step="1.00"
                    value={qty_shipped}
                    placeholder="Quantity Shipped"
                    className="input"
                    onChange={handleChangeqty_shipped}
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">New Qty Reject: </label>
                  <input
                    required
                    type="number"
                    name="qty_shipped"
                    step="1.00"
                    value={qty_reject}
                    placeholder="Quantity Shipped"
                    className="input"
                    onChange={handleChangeqty_reject}
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Mfg Date: </label>
                  <input
                    type="date"
                    name="ship_date"
                    value={formData.mfg_date}
                    className="input"
                    onChange={handleChangeMfgDate}
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
            </Col>
            <Col md={9} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <input
                    style={{
                      width: "93.6667%",
                    }}
                    placeholder="Note "
                    id="noteTextarea"
                    value={acc_notes}
                    onChange={handleChangeAccNote}
                    rows="2"
                    className="input"
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <button
          style={{
            backgroundColor: !forceUpdate ? "grey" : currentColor,
            color: "#fff",
            borderRadius: "10px",
            margin: "10px",
            padding: "20px",
          }}
          onClick={handleViewEmployeesClick}
          disabled={!forceUpdate}
        >
          Save Order
        </button>
        <button
          style={{
            backgroundColor: currentColor,
            color: "#fff",
            borderRadius: "10px",
            margin: "10px",
            padding: "20px",
          }}
          onClick={handleBackClick}
          text="heh"
        >
          Back
        </button>
      </Row>
    </div>
  );
};

export default ReceiveLog;
