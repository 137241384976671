import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { deleteJournalById, getAllJournals } from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Journal = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [r_id, setr_id] = useState("");
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const { promiseInProgress } = usePromiseTracker();
  const [JDate, setJDate] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const customerGridImage2 = (props) => <div>{"J" + props.journal_id}</div>;
  // const jounralDebitCredit = (props) =>
  //   props.debit === 0 ? <div>{"-"}</div> : <div>{props.debit}</div>;

  const isCurrentYear = (date) =>
    new Date(
      date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
    ).getUTCFullYear() === new Date().getUTCFullYear();

  const customersGrid = [
    {
      headerText: "ID",
      field: "journal_id",
      template: customerGridImage2,
      minWidth: "90",
      width: "90",
      maxWidth: "100",
      textAlign: "right",
    },
    {
      field: "datetime",
      headerText: "DateTime",
      minWidth: "140",
      width: "140",
      maxWidth: "180",
      textAlign: "Center",
    },
    {
      field: "account",
      headerText: "Account",
      minWidth: "180",
      width: "200",
      maxWidth: "420",
      textAlign: "left",
    },
    // {
    //   field: "amount",
    //   headerText: "Amount",
    //   minWidth: "140",
    //   width: "140",
    //   maxWidth: "180",
    //   format: "C2",
    //   textAlign: "right",
    // },
    {
      field: "debit",
      headerText: "Debit",
      minWidth: "140",
      width: "140",
      maxWidth: "180",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "credit",
      headerText: "Credit",
      minWidth: "140",
      width: "140",
      maxWidth: "180",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "notes",
      headerText: "Note",
      minWidth: "140",
      width: "320",
      maxWidth: "640",
      textAlign: "left",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("Add new");
      navigate(`/Journal/AddJournal/${storeG.store_id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("edit new");
      if (r_id !== "") {
        if (isCurrentYear(JDate)) {
          navigate(`/Journal/EditJournal/${r_id}`);
        } else {
          alert(`J${r_id} was created previous year!\nIt is not editable.`);
        }
      } else {
        alert("Please select journal to edit.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick1 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("delete");
      if (r_id !== "") {
        if (isCurrentYear(JDate)) {
          if (
            window.confirm(`Are you sure you want to delete J${r_id} journal?`)
          ) {
            const resp = await deleteJournalById(r_id, userID, userToken);
            if (resp.status === 200) {
              navigate("/Journal");
              alert("Journal deleted successfully.");
              window.location.reload();
            } else {
              alert("Journal failed to delete.");
            }
          }
        } else {
          alert(`J${r_id} was created previous year!\nIt can not be deleted.`);
        }
      } else {
        alert("Please select journal to delete.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setr_id(selectedRowData.journal_id);
    setJDate(selectedRowData.datetime);
    //console.log(selectedRowData.journal_id);
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      //console.log(store_id);
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([getAllJournals(storeG.store_id, userID, userToken)])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  const settings = { checkboxMode: "ResetOnRowClick" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Financials" title="JOURNAL" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5038) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5039) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick1}
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            selectionSettings={settings}
            rowHeight={36}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Journal;
