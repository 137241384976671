import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  //GetAllVendorsName,
  //GetProductNameCodeInv,
  GetProductByIdSale,
  //GetSaleCustomerById,
  //GetProductsByStoreVendorId,
  GetPurchaseOrderDetailsByID,
  GetPurchaseOrderVendorByID,
  DeleteEditPurchaseOrderProduct,
  EditPurchaseOrderAPi,
  //RemoveProd_fromShipmentTrans,
  //GetProductByStoreID,
  //EditPurchaseStatusBYPo_id,
  getProductFilterSubString,
  //getAccNamesCash,
  GetAccToIdByPusOrder,
  getAccNamesAssetCash,
  GetUnitByProductId,
  // GetVendorProductFilterSubString,
} from "../../api";
import Select from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/viewStore.css";
import { Card } from "react-bootstrap";
//import Sidebar from "../../components/ViewOrderProduct";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Header } from "../../components";
import { openNewTab } from "../../contexts/PO_Invoice";
import { FcInfo } from "react-icons/fc";

const EditPurchaseOrder = () => {
  const {
    currentColor,
    //activeProdMenu,
    setActiveProdMenu,
    setActiveProdMenuId,
    setActivePayment,
    setActivePaymentId,
    userID,
    userToken,
  } = useStateContext();
  //const navigate = useNavigate();
  //const [GetVendor, setGetVendor] = useState([]);
  //const [Vendor, setVendor] = useState("");
  //const [VendorOptions, setVendorOptions] = useState([]);
  //const [GetProduct, setGetProduct] = useState([]);
  const [product, setProducts] = useState("");
  const [qty, setqty] = useState(1.0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [productOptions, setProductOptions] = useState([]);
  const [p_code, setp_code] = useState("");
  const [Vendor_ids, setVendor_ids] = useState("");
  const [CardList, setcartList] = useState([]);
  const [total_amount, settotalAmount] = useState(0);
  const [tax, settax] = useState(0);
  const [totaldiscount, settotaldiscount] = useState(0.0);
  const [total_item, settotalitem] = useState(0);
  const [index1, setindex1] = useState("");
  const [grandtotal, setgrandtotal] = useState(0);
  const [note, setnote] = useState("");
  //const [VendorDetail, setVendorDetail] = useState([]);
  //const [cusPhone, setcusPhone] = useState("");
  const [trackingno, settrackingno] = useState("");
  const [projectname, setprojectname] = useState("");
  const [shipmethod, setshipmethod] = useState("");
  const [cusFlag, setcusFlag] = useState("");
  //const [invoice_id, setinvoice_id] = useState("");
  const [OrderStatus, setOrderStatus] = useState("");
  //const [status_id, setstatus_id] = useState("");
  const [orderstatuslable, setorderstatuslable] = useState("");
  const [deleteprodList, setdeleteprodList] = useState([]);
  //const [ValError, setValError] = useState([]);
  //const [unshippedList, setunshippedList] = useState([]);
  const [Invoice_No, setInvoice_No] = useState("");
  const [qtyShipped, setqtyShipped] = useState([]);
  const [ProductStr, setProductStr] = useState("");
  const [paym, setpaym] = useState("");
  const [acc_from_id, setacc_from_id] = useState("");
  const [acc_to_id, setacc_to_id] = useState("");
  const [amount_paid, setamount_paid] = useState(0);
  const [amount_pend, setamount_pend] = useState(0);
  const [getacc_tos, setGetacc_tos] = useState([]);
  const [acc_to, setacc_to] = useState("");
  const [acc_from_bal, setacc_from_bal] = useState("");
  //const [productID, setproductID] = useState("");
  //const [toggle, setToggle] = useState(false);
  const [dot, setdot] = useState(0);
  const [isPageFrozen, setIsPageFrozen] = useState(false);
  //const [req_date, setreq_date] = useState();
  const [saveClick, setsaveClick] = useState(false);
  const [store_id_param, setstore_id_param] = useState("");
  //const [spec_ord_flag, setspec_ord_flag] = useState(false);
  const [ppo_id, setppo_id] = useState(0);
  const [print_flag, setprint_flag] = useState(false);
  const [DirectShipping_flag] = useState(false);
  let param = useParams();
  // console.log(param.so_ids)
  // const paramString = String(param.po_ids);
  // const [so_id_param, store_id_param] = paramString.split("_");
  var so_id_param = param.po_ids;

  const [formData] = useState({
    po_id: 0,
    vendor_id: "",
    vendor_invoice_no: "",
    user_id: null,
    store_id: 0,
    ship_method: "",
    tracking_no: "",
    total: 0,
    amount_paid: 0,
    amount_pending: 0,
    status_id: 0,
    po_note: "",
    t_type_id: 0,
    purchase_products: [],
    receive_logs: [],
    o_datetime: "",
  });

  const [deleteformData1, setdeleteformData1] = useState({
    po_id: so_id_param,
    store_id: 0,
    product_id: 0,
    item_no: 0,
  });

  const keypadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    ".",
    "0",
    "00",
  ];

  const [activeInput, setActiveInput] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [UnitOptions, setUnitOptions] = useState([]);
  const [GetUnit, setGetUnit] = useState([]);
  const [oDatetime, setODatetime] = useState("");
  //const [selectedVendor, setSelectedVendor] = useState(null);

  const handleInputClick = (inputId, index) => {
    TimeoutUtility.resetTimeout();
    setActiveInput(inputId);
    setindex1(index);
  };

  // const handleChangeActive = (index) => {
  //   const newCartList = [...CardList];
  //   newCartList[index].receive = newCartList[index].receive === 0 ? 1 : 0;
  //   setcartList(newCartList);
  // };

  const handleKeypadClick = (value) => {
    TimeoutUtility.resetTimeout();
    // try {
    if (activeInput !== null) {
      const newCartList = [...CardList];
      const indexToUpdate = index1;

      if (
        activeInput === "quantity" ||
        activeInput === "unit_price" ||
        activeInput === "discount" ||
        activeInput === "receive"
      ) {
        var currentValue = String(
          newCartList[indexToUpdate][activeInput] || ""
        );
        // console.log(currentValue);
        if (value === "." && currentValue.includes(".")) {
          return;
        }

        if (value === "." && !currentValue.includes(".")) {
          if (currentValue === "") {
            currentValue = "0";
          }
          newCartList[indexToUpdate][activeInput] = currentValue + ".0";
          setcartList(newCartList);
          setdot(1);
          return;
        }
        var newValue = currentValue + String(value);
        if (dot === 1) {
          var [integerPart] = currentValue.split(".");
          if (integerPart === "") {
            integerPart = "0";
          }
          newValue = integerPart + "." + value;
          setdot(0);
        }

        if (!isNaN(newValue)) {
          // console.log(indexToUpdate + "  " + activeInput);
          newCartList[indexToUpdate][activeInput] = newValue;
          newCartList[indexToUpdate].total =
            newCartList[indexToUpdate].unit_price *
              newCartList[indexToUpdate].quantity -
            newCartList[indexToUpdate].discount *
              newCartList[indexToUpdate].quantity;
          //-newCartList[indexToUpdate].discount;

          setcartList(newCartList);
          setdot(0);
        }
      } else if (activeInput === "tax" && index1 === -2) {
        const currentTaxValue = String(tax || "");

        if (value === "." && currentTaxValue.includes(".")) {
          return;
        }

        const newValue = currentTaxValue + String(value || "");
        if (!isNaN(newValue)) {
          settax(newValue);
        }
      } else if (activeInput === "amount_paid" && index1 === -4) {
        const currentShipmentValue = String(amount_paid || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }

        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setamount_paid(newValue);
          setamount_pend(grandtotal - newValue);
        }
      } else if (activeInput === "qty" && index1 === -8) {
        const currentShipmentValue = String(qty || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }
        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setqty(newValue);
        }
      } else if (activeInput === "uprice" && index1 === -9) {
        const currentShipmentValue = String(unitPrice || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }
        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setUnitPrice(newValue);
        }
      }
    }
  };

  const handleAddcartClick = () => {
    TimeoutUtility.resetTimeout();
    // const isProductInCart = CardList.some(
    //   (item) => item.product_id === product.value
    // );
    //if (!isProductInCart && product.value) {

    if (qty <= 0) {
      alert("Quantity should be greater than 0!");
      return;
    } else if (selectedUnit === null) {
      alert("Select Unit!");
      return;
    } else if (product.value && userID && userToken) {
      let qtyy = qty;
      let upp = unitPrice;
      if (selectedUnit === UnitOptions[1]) {
        qtyy = qty / selectedUnit.conv;
        upp = unitPrice / UnitOptions[0].conv;
      }
      const resp1 = GetProductByIdSale(
        p_code,
        store_id_param,
        userID,
        userToken
      );
      resp1
        .then(function (result) {
          const defaultProduct = {
            product_id: result.data[0].product_id,
            item_no: 0,
            name: result.data[0].name,
            code: result.data[0].code,
            unit_price: upp,
            unit: UnitOptions[0].label,
            cost_price: result.data[0].cost_price,
            quantity: qtyy,
            discount: 0,
            total: unitPrice * qty,
            // - result.data[0].discount,
            notes: "",
            // receive: 0,
            receive: qtyy,
            image: result.data[0].image,
            details: result.data[0].details,
          };

          setcartList((prevProductList) => [
            defaultProduct,
            ...prevProductList,
          ]);
          setProducts("");
          // setSelectedProduct("");
          // setqty(1);
          // setUnitPrice(0);

          // setProductOptions((prevOptions) =>
          //   prevOptions.filter(
          //     (option) => option.values !== result.data[0].product_id
          //   )
          // );
          setSelectedProduct("");
          setqty(1);
          setUnitPrice(0);
          setSelectedUnit("");
          setUnitOptions([]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // const handleChangeVendor = (selectedOption) => {
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedVendor(selectedOption);
  //     setActiveInput("vendor");
  //     setVendor(selectedOption);
  //     setVendor_ids(selectedOption.value);
  //     getVendorDetail(selectedOption.value);
  //     if (selectedOption.value) {
  //       setGetProduct([]);
  //       // getProductsAll(selectedOption.value);
  //     } else {
  //       setGetProduct([]);
  //     }
  //     setcartList([]);
  //     settax(0);
  //     settotaldiscount(0);
  //     settotalitem(0);
  //     setSelectedProduct("");
  //     setnote("");
  //     settrackingno("");
  //     setprojectname("");
  //     setshipmethod("");
  //   }
  // };

  const handleChangeProduct = async (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.values && userID && userToken) {
      setSelectedProduct(selectedOption);
      setqty(1);
      setUnitPrice(selectedOption.price);
      await GetUnitByProductId(selectedOption.values, userID, userToken)
        .then((resp) => {
          setGetUnit(resp.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setActiveInput("product");
      var ProductStr1 = ProductStr;
      ProductStr1 = ProductStr1 + selectedOption.values + " ";
      setProductStr(ProductStr1);
      setProducts(selectedOption);
      const selectedProduct = selectedOption.values;
      setp_code(selectedProduct);
      document.getElementById("unit").focus();
    }
  };

  // const handleChangeProduct1 = (selectedOption, event) => {
  //   TimeoutUtility.resetTimeout();
  //   if (event.key === "Enter" && selectedOption && selectedOption.values) {
  //     setSelectedProduct(selectedOption);
  //     setqty(1);
  //     setUnitPrice(0);
  //     setActiveInput("product");
  //     setProducts(selectedOption);
  //     const selectedProduct = selectedOption.values;
  //     setp_code(selectedProduct);
  //     handleAddcartClick();
  //   }
  // };

  const handleChangeUnit = (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.value) {
      if (selectedOption !== selectedUnit) {
        setUnitPrice(unitPrice / selectedOption.conv);
      }
      setSelectedUnit(selectedOption);
      setActiveInput("unit");
      // setunit_ids(selectedOption.value);
      // setunit_name(selectedOption.label);
      document.getElementById("qty").focus();
      //} else {
      // setSelectedUnit(null);
      // setActiveInput("unit");
      // setunit_ids("");
      // setunit_name("");
    }
  };

  const handleChangeUnit1 = (selectedOption, event) => {
    TimeoutUtility.resetTimeout();
    if (event.key === "Enter" && selectedOption && selectedOption.value) {
      if (selectedOption !== selectedUnit) {
        setUnitPrice(unitPrice / selectedOption.conv);
      }
      setSelectedUnit(selectedOption);
      setActiveInput("unit");
      // setunit_ids(selectedOption.value);
      // setunit_name(selectedOption.label);
      document.getElementById("qty").focus();
      // } else {
      // setSelectedUnit(null);
      // setActiveInput("unit");
      // setunit_ids("");
      // setunit_name("");
    }
  };

  const handleChangeQty = (e) => {
    setActiveInput("qty");
    setqty(e.target.value);
    //setindex1(-2);
  };

  const handleEnterQty = (e) => {
    if (e.key === "Enter") {
      document.getElementById("uprice").focus();
    }
  };

  const handleChangeUPrice = (e) => {
    setActiveInput("uprice");
    setUnitPrice(e.target.value);
    //setindex1(-2);
  };

  const handleEnterUPrice = (e) => {
    if (e.key === "Enter") {
      handleAddcartClick();
      document.getElementById("ProductSelect").focus();
    }
  };

  const handleChangeAmountPaid = (e) => {
    TimeoutUtility.resetTimeout();
    setActiveInput("amount_paid");
    setamount_paid(e.target.value);
    setamount_pend(grandtotal - e.target.value);
    setindex1(-4);
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const handleInputChange = (inputValue) => {
    TimeoutUtility.resetTimeout();
    // if (Supplier_ids) {
    if (inputValue && inputValue.length >= 2 && userID && userToken) {
      const searchResults = getProductFilterSubString(
        // Vendor_ids,
        // store_id_param,
        // inputValue,
        // userID,
        // userToken
        // ProductStr
        store_id_param,
        inputValue,
        ProductStr,
        userID,
        userToken
      );
      searchResults
        .then((response) => {
          const productsArray = response.data;
          // const filteredProducts = productsArray.filter(
          //   (product) =>
          //     !CardList.some((item) => item.product_id === product.product_id)
          // );
          //const newProductOptions = filteredProducts.map((item) => ({
          const filteredProducts = productsArray.filter(
            (product) => new Date(product.open_datetime) <= new Date(oDatetime)
          );
          const newProductOptions = filteredProducts.map((item) => ({
            values: item.product_id,
            price: item.cost_price,
            last_so_info:
              "Last Price: " +
              (item.last_price || "-") +
              " - SO#" +
              (item.last_so_id || "-") +
              " - SaleDate: " +
              (item.last_sale_date
                ? new Date(item.last_sale_date).toISOString().split("T")[0]
                : "-"),
            value: `${item.code} ${item.productname} ${
              item.details
            } ${formatCurrency(item.cost_price)}`.toLowerCase(),
            label: (
              <div
                style={{
                  display: "flex",
                  marginTop: "4px",
                }}
              >
                {item.image && (
                  <div
                    style={{
                      flex: "0 0 10%",
                    }}
                  >
                    <img
                      className="rounded-xl"
                      src={`data:image/jpeg;base64,${item.image}`}
                      alt={`Product ${item.code}`}
                      style={{
                        maxWidth: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    flex: item.image ? "0 0 76%" : "0 0 86%",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>{`${
                      item.code
                    } ${truncate(
                      item.productname,
                      item.image ? 48 : 54
                    )}`}</div>
                  </div>
                  <div style={{ fontSize: "14px" }}>{`${truncate(
                    item.details,
                    item.image ? 68 : 76
                  )}`}</div>
                </div>
                <div
                  style={{
                    flex: "0 0 14%",
                    fontWeight: "500",
                    color: currentColor,
                    textAlign: "right",
                    verticalAlign: "middle",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "calc(0.6vw + 4px)",
                  }}
                >{`${formatCurrency(item.cost_price)}`}</div>
              </div>
            ),
          }));
          setProductOptions(newProductOptions);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else {
      // setProductOptions(productOptions1);
    }
    // }
  };

  const customFilter = (option, inputValue) => {
    const optionSearchField = String(option.value).toLowerCase();
    const lowerCasedInput = inputValue.toLowerCase();
    return optionSearchField.includes(lowerCasedInput);
  };

  const handleChangeNote = (e) => {
    TimeoutUtility.resetTimeout();
    setnote(e.target.value);
  };
  // const handleChangeProjectName = (e) => {
  //   setprojectname(e.target.value);
  // };

  const handleChangeInvNo = (e) => {
    setInvoice_No(e.target.value);
  };

  const handleAccToChange = (e) => {
    TimeoutUtility.resetTimeout();
    setacc_to(e.target.value);
  };

  const handleChangeTrackingNo = (e) => {
    settrackingno(e.target.value);
  };

  const handleChangeShipMethod = (e) => {
    setshipmethod(e.target.value);
  };

  // const getVendorDetail = async (id) => {
  //   try {
  //     // const resp = await GetSaleCustomerById(id);
  //     // setVendorDetail(resp.data || []);
  //     // // console.log(resp.data[0].phone);
  //     // setcusPhone(resp.data[0].phone);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const getProductsAll = async (v) => {
  //   try {
  //     const resp = await GetProductsByStoreVendorId(store_id_param, v);
  //     setGetProduct(resp.data || []);
  //     console.log(resp.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const UpdateStatus = async (id, stat) => {
  //   // imgg.preventDefault();
  //   const response = await EditPurchaseStatusBYPo_id(stat, id);
  //   if (response.status === 200) {
  //     // if(response.data[0][0].result){
  //     alert("Purchase order status updated successfully.");
  //     // }
  //     // else{
  //     //   alert("Order Status Failed to Update!");
  //     // }
  //   } else {
  //     alert("Failed to update purchase order status.");
  //   }
  // };

  const handleSaleOrderClick = async (e) => {
    TimeoutUtility.resetTimeout();
    if (CardList.length === 0) {
      alert("Purchase order cart is empty.");
      return;
    }
    setIsPageFrozen(true);
    setsaveClick(!saveClick);
  };

  // const handlePrintOrderClick = async (e) => {
  //   TimeoutUtility.resetTimeout();
  //   setprint_flag(true);
  //   handleSaleOrderClick();
  // };

  useEffect(() => {
    async function saveData() {
      if (saveClick && userID && userToken) {
        let ss = 72;
        if (orderstatuslable === "CLOSE") {
          ss = 73;
        }

        const updatedFormData = { ...formData };
        updatedFormData.po_id = so_id_param;
        updatedFormData.vendor_id = Vendor_ids;
        updatedFormData.vendor_invoice_no = Invoice_No;
        updatedFormData.user_id = userID;
        updatedFormData.store_id = store_id_param;
        updatedFormData.ship_method = shipmethod;
        updatedFormData.tracking_no = trackingno;
        updatedFormData.total = grandtotal;
        updatedFormData.amount_paid = amount_paid;
        updatedFormData.amount_pending = grandtotal - amount_paid;
        updatedFormData.status_id = ss;
        updatedFormData.po_note = note;
        updatedFormData.t_type_id = 708;
        updatedFormData.o_datetime = oDatetime.replace("T", " ");
        updatedFormData.acc_from_id = acc_from_id;
        updatedFormData.acc_to_id = acc_to_id;

        updatedFormData.purchase_products = [];
        updatedFormData.receive_logs = [];

        // console.log(qtyShipped);
        var ff = 0,
          xx = null;
        const uniqueValues = new Set(
          CardList.map((v) => String(v.product_id) + v.notes)
        );

        if (uniqueValues.size < CardList.length) {
          //console.log(uniqueValues);
          alert(
            `Duplicate notes for same products found.\nPurchase Order failed to create.`
          );
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          return;
        } else {
          CardList.forEach((product) => {
            const qtyShippedProduct = qtyShipped.find(
              (item) => parseInt(item.item_no) === parseInt(product.item_no)
            );
            // console.log("Product:", product);
            // console.log("QtyShippedProduct:", qtyShippedProduct);
            if (DirectShipping_flag) {
              if (parseFloat(product.receive) <= parseFloat(product.quantity)) {
                if (qtyShippedProduct) {
                  const receivedQuantity =
                    parseFloat(product.quantity) -
                    parseFloat(qtyShippedProduct.receive);
                  // console.log(parseInt(qtyShippedProduct.receive));

                  //if (receivedQuantity !== 0) {
                  const shipment = {
                    // recv_status_id: a,
                    quantity: product.quantity,
                    product_id: product.product_id,
                    quantity_recv: receivedQuantity,
                    quantity_reject: 0,
                    mfg_date: null,
                    recv_by: projectname,
                    recv_note: product.notes,
                    old_unit_price: qtyShippedProduct.unit_price,
                    old_discount: qtyShippedProduct.discount,
                    old_qty_rcv: qtyShippedProduct.receive,
                  };
                  updatedFormData.receive_logs.push(shipment);
                  //}
                } else {
                  const shipment = {
                    //recv_status_id: a,
                    quantity: product.quantity,
                    product_id: product.product_id,
                    quantity_recv: product.receive,
                    quantity_reject: 0,
                    recv_by: projectname,
                    mfg_date: null,
                    recv_note: product.notes,
                    old_unit_price: 0,
                    old_discount: 0,
                    old_qty_rcv: 0,
                  };
                  updatedFormData.receive_logs.push(shipment);
                }
              } else {
                if (ff === 0) {
                  xx = product.code;
                  ff = 1;
                }
              }
              // } else if (parseFloat(product.receive) > 0) {
            } else {
              // let a = 72;
              // if (parseFloat(product.receive) === parseFloat(product.quantity)) {
              //   a = 73;
              // }
              //console.log("Condition 1: Receive > 0");
              if (parseFloat(product.receive) <= parseFloat(product.quantity)) {
                if (qtyShippedProduct) {
                  // console.log("Condition 2: QtyShippedProduct found");
                  const receivedQuantity =
                    parseFloat(product.receive) -
                    parseFloat(qtyShippedProduct.receive);
                  // console.log(parseInt(qtyShippedProduct.receive));

                  // if (receivedQuantity !== 0) {
                  const shipment = {
                    // recv_status_id: a,
                    quantity: product.quantity,
                    product_id: product.product_id,
                    quantity_recv: receivedQuantity,
                    quantity_reject: 0,
                    mfg_date: null,
                    recv_by: projectname,
                    recv_note: product.notes,
                    old_unit_price: qtyShippedProduct.unit_price,
                    old_discount: qtyShippedProduct.discount,
                    old_qty_rcv: qtyShippedProduct.receive,
                  };
                  updatedFormData.receive_logs.push(shipment);
                  //}
                } else {
                  // console.log("Condition 3: QtyShippedProduct not found");
                  const shipment = {
                    //recv_status_id: a,
                    quantity: product.quantity,
                    product_id: product.product_id,
                    quantity_recv: product.receive,
                    quantity_reject: 0,
                    recv_by: projectname,
                    mfg_date: null,
                    recv_note: product.notes,
                    old_unit_price: 0,
                    old_discount: 0,
                    old_qty_rcv: 0,
                  };
                  updatedFormData.receive_logs.push(shipment);
                }
              } else {
                if (ff === 0) {
                  xx = product.code;
                  ff = 1;
                }
              }
            }

            const saleProduct = {
              item_no: product.item_no,
              product_id: product.product_id,
              quantity: product.quantity,
              unit_price: product.unit_price,
              discount: product.discount,
              exp_rec_date: null,
              item_note: product.notes,
            };
            updatedFormData.purchase_products.push(saleProduct);
          });
          // console.log(updatedFormData);

          if (ff === 1) {
            alert(
              `Receive qty of ${xx} must be less or equal to total qty.\nPurchase Order failed to update.`
            );
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
          // setFormData(updatedFormData);

          if (deleteprodList.length === 0) {
          } else {
            DeleteProductfrom_SaleOrder();
          }
          console.log(updatedFormData);
          // if (unshippedList.length === 0) {
          // } else {
          //   DeleteUnshippedProductsfrom_SaleOrder();
          // }

          const response = await EditPurchaseOrderAPi(
            updatedFormData,
            userID,
            userToken
          );
          if (response.status === 200) {
            // if (OrderStatus === "MARK AS OPEN") {
            //   console.log(response.data[0][0].po_id);
            //   if (response.data[0][0].po_id) {
            //     console.log(response.data[0][0].po_id);
            //     UpdateStatus(response.data[0][0].po_id, 72);
            //   }
            // } else {
            //   if (response.data[0][0].po_id) {
            //     console.log(response.data[0][0].po_id);
            //     UpdateStatus(response.data[0][0].po_id, 73);
            //   }
            // }
            if (print_flag && so_id_param) {
              alert("Purchase order updated successfully");
              await openNewTab(so_id_param);
              setprint_flag(false);
              window.location.reload();
            }
            if (!print_flag) {
              alert("Purchase order updated successfully");
            }
          } else {
            alert("Purchase order failed to update");
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }

          if (!print_flag) {
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            window.location.reload();
          }
        }
      }
    }
    saveData();
  }, [saveClick]);

  const handleStatusClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    if (CardList.length === 0) {
      alert("Purchase order cart is empty");
      return;
    }
    if (orderstatuslable === "CLOSE") {
      // if (window.confirm(`Order is already closed?\nStill want to continue?`)) {
      setOrderStatus("MARK AS CLOSE");
      setorderstatuslable("OPEN");
      return;
      // } else {
      //   // return;
      // }
    } else {
      if (orderstatuslable === "OPEN") {
        let f = 0;
        CardList.forEach((element) => {
          if (parseInt(element.quantity) > parseInt(element.receive)) {
            f = 1;
          } else {
            setOrderStatus("MARK AS OPEN");
            setorderstatuslable("CLOSE");
            return;
          }
        });
        if (f === 1) {
          if (
            window.confirm(
              `Quantity is not completely Received.\nStill want to Continue?`
            )
          ) {
            setOrderStatus("MARK AS OPEN");
            setorderstatuslable("CLOSE");
            return;
          }
        }
      }
    }
  };

  const handleClearClick = async (event) => {
    TimeoutUtility.resetTimeout();
    if (window.confirm(`Are you sure you want to Clear the purchase order?`)) {
      setcartList([]);
      setshipmethod("");
      settrackingno("");
      setprojectname("");
      settax(0);
      setProductOptions([]);
      setnote("");
      setSelectedProduct("");
      setqty(1);
      setUnitPrice(0);
      setGetUnit([]);
      // setSelectedSupplier("");
      setInvoice_No("");
    }
  };

  const handleToggle = (value) => {
    TimeoutUtility.resetTimeout();
    //setToggle((pre) => !pre);
    // if (!viewOrderProductOpen) {
    //   openViewOrderProduct();
    // }
    setActiveProdMenuId({
      product_id: value.product_id,
      store_id: store_id_param,
    });
    setActivePayment(false);
    setActiveProdMenu(true);
    //setproductID(value.product_id);
  };

  const handlePaymentToggle = () => {
    TimeoutUtility.resetTimeout();
    setActivePaymentId({
      so_id: null,
      po_id: so_id_param,
    });
    setActiveProdMenu(false);
    setActivePayment(true);
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    if (
      window.confirm(
        `Are you sure you want to Close without saving the changes?`
      )
    ) {
      // console.log(CardList);
      window.close();
    }
  };

  const handleNewClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Add new");
      const path = `/Purchase/addPurchaseOrder/${param.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CalculateAllFields = () => {
    TimeoutUtility.resetTimeout();
    let total_amt = 0,
      total_itm = 0;
    let total_dis = 0;
    let grandTotal = 0.0;
    //let taxx = 0;

    // if (tax) {
    //   taxx = parseFloat(tax);
    // }
    for (let i = 0; i < CardList.length; i++) {
      let a = 0,
        b = 0,
        c = 0;
      if (CardList[i].total) {
        a = parseFloat(CardList[i].total);
      }
      if (CardList[i].discount) {
        b = parseFloat(CardList[i].discount);
      }
      if (CardList[i].quantity) {
        c = parseFloat(CardList[i].quantity);
      }

      total_amt = total_amt + a + b * c;
      total_dis = total_dis + b * c;
      total_itm = total_itm + c;

      // grandTotal = ((parseFloat(CardList[i].unit_price) - parseFloat(CardList[i].discount)) * parseFloat(CardList[i].quantity));
    }
    settotaldiscount(total_dis);
    settotalAmount(total_amt);
    settotalitem(total_itm);

    grandTotal = total_amt - total_dis;
    //grandTotal = total_amt;
    setgrandtotal(grandTotal);
    setamount_pend(grandTotal - amount_paid);
  };

  const handleNotesChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].notes = value;
    setcartList(newCartList);
    setActiveInput("notes");
    setindex1(index);
  };

  const handleQuantityChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].quantity = value;
    newCartList[index].receive = value;
    newCartList[index].total =
      value * parseFloat(newCartList[index].unit_price) -
      value * parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("quantity");
    setindex1(index);
  };

  // const handlereceiveChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   // if (value > newCartList[index].quantity) {
  //   // if (
  //   //   window.confirm(
  //   //     `Receive qty greater than total qty.\nStill want to continue?`
  //   //   )
  //   // ) {
  //   //   alert("Receive qty must be less or equal to total qty.");
  //   //   newCartList[index].receive = 0;
  //   //   setcartList(newCartList);
  //   //   setActiveInput("receive");
  //   //   setindex1(index);
  //   //   // }
  //   // } else if (
  //   //   value < newCartList[index].quantity &&
  //   //   orderstatuslable === "CLOSE"
  //   // ) {
  //   //   if (window.confirm(`Order is already Closed.\nStill want to continue?`)) {
  //   //     newCartList[index].receive = value;
  //   //     setcartList(newCartList);
  //   //     setActiveInput("receive");
  //   //     setindex1(index);
  //   //   }
  //   // } else {
  //   newCartList[index].receive = value;
  //   setcartList(newCartList);
  //   setActiveInput("receive");
  //   setindex1(index);
  //   // }
  // };

  // const handlereceiveClick = (inputId, index) => {
  //   setActiveInput(inputId);
  //   setindex1(index);
  // };

  const handleDiscountChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].discount = value;
    newCartList[index].total =
      newCartList[index].quantity * newCartList[index].unit_price -
      newCartList[index].quantity * value;
    setcartList(newCartList);
    setActiveInput("discount");
    setindex1(index);
  };

  const handleUnitPriceChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].unit_price = value;
    newCartList[index].total =
      value * parseFloat(newCartList[index].quantity) -
      parseFloat(newCartList[index].quantity) *
        parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("unit_price");
    setindex1(index);
  };

  const formatCurrency = (number) => {
    return number.toLocaleString("en-PK", {
      style: "currency",
      currency: "PKR",
      minimumFractionDigits: 2,
    });
  };

  // const handleChangeTax = (e) => {
  //   setActiveInput("tax");
  //   settax(e.target.value);
  //   setindex1(-2);
  // };

  const DeleteProductfrom_SaleOrder = async () => {
    TimeoutUtility.resetTimeout();
    const updatedFormData = { ...deleteformData1 };
    updatedFormData.store_id = store_id_param;
    //updatedFormData.invoice_id = invoice_id;
    setdeleteformData1(updatedFormData);

    try {
      await Promise.all(
        deleteprodList.map(async (item) => {
          if (item.item_no !== 0 && userID && userToken) {
            const deleteFormData = {
              ...updatedFormData,
              product_id: item.product_id,
              item_no: item.item_no,
            };
            const response = await DeleteEditPurchaseOrderProduct(
              deleteFormData,
              userID,
              userToken
            );

            if (response.status !== 200) {
              console.error(`Product ${item.product_id} failed to remove.`);
            }
          }
        })
      );
    } catch (error) {
      console.error("Error during product removal:", error);
      alert("An error occurred during product removal.");
    }
  };

  // const handleShipmentChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   if (value < newCartList[index].quantity) {
  //     if (
  //       window.confirm(
  //         `Receive qty less than total qty.\nStill want to continue?`
  //       )
  //     ) {
  //       newCartList[index].receive = value;
  //       setcartList(newCartList);
  //       setActiveInput("receive");
  //       setindex1(index);
  //     }
  //   } else if (
  //     value < newCartList[index].quantity &&
  //     orderstatuslable === "CLOSE"
  //   ) {
  //     if (
  //       window.confirm(`Order is already received.\nStill want to continue?`)
  //     ) {
  //       newCartList[index].receive = value;
  //       setcartList(newCartList);
  //       setActiveInput("receive");
  //       setindex1(index);
  //     }
  //   } else {
  //     newCartList[index].receive = value;
  //     setcartList(newCartList);
  //     setActiveInput("receive");
  //     setindex1(index);
  //   }
  // };

  // const DeleteUnshippedProductsfrom_SaleOrder = async () => {
  //   console.log(unshippedList);
  //   const shippableProductIds = CardList.filter(
  //     (product) => product.receive > 0
  //   ).map((product) => product.product_id);
  //   const updatedUnshippedList = unshippedList.filter(
  //     (productId) => !shippableProductIds.includes(productId)
  //   );
  //   setunshippedList(updatedUnshippedList);
  //   const updatedFormData = { ...deleteformData1 };
  //   updatedFormData.invoice_id = invoice_id;
  //   setFormData1(updatedFormData);
  //   console.log("heheh " + updatedUnshippedList);
  //   try {
  //     await Promise.all(
  //       updatedUnshippedList.map(async (productId) => {
  //         const deleteFormData = { ...updatedFormData, product_id: productId };
  //         const response = await RemoveProd_fromShipmentTrans(deleteFormData);

  //         if (response.status !== 200) {
  //           console.error(`Product receive  ${productId} failed to remove.`);
  //         }
  //       })
  //     );
  //   } catch (error) {
  //     console.error("Error during product receive removal:", error);
  //     alert("An error occurred during product receive removal.");
  //   }
  // };

  const handleDeleteClick = async (index) => {
    TimeoutUtility.resetTimeout();
    const removedProduct = CardList[index];
    if (removedProduct.receive === 0) {
      if (
        window.confirm(
          `Do you want to remove ${removedProduct.code} from Cart?`
        )
      ) {
        setdeleteprodList((prevArray) => [
          ...prevArray,
          {
            product_id: removedProduct.product_id,
            item_no: removedProduct.item_no,
          },
        ]);
        const item1 = CardList[index].product_id;
        const newCartList = [...CardList];
        newCartList.splice(index, 1);
        setcartList(newCartList);

        const currentIdsArray = ProductStr.split(" ");
        const updatedIdsArray = currentIdsArray.filter(
          (id) => id !== String(item1)
        );
        const updatedProductIds = updatedIdsArray.join(" ");
        setProductStr(updatedProductIds);
        // alert("Removed successfully.");
      }
    } else {
      alert("Receive qty is greater than zero. So, it could not be deleted.");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "70px",
      height: "70px",
      fontSize: "14px",
    }),
    option: (provided) => ({
      ...provided,
      maxHeight: "70px",
      fontSize: "14px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "250px",
    }),
  };

  useEffect(() => {
    const fetchUnitOptions = async () => {
      if (GetUnit && GetUnit.length > 0) {
        const fetchedOption = [];
        fetchedOption.push({
          label: `${GetUnit[0].name}`,
          value: GetUnit[0].unit_id,
          conv: parseFloat(GetUnit[0].conv_rate),
        });
        if (GetUnit[0].parent) {
          fetchedOption.push({
            label: `${GetUnit[0].pname}`,
            value: GetUnit[0].parent,
            conv: 1 / parseFloat(GetUnit[0].conv_rate),
          });
        }
        setUnitOptions(fetchedOption);
        if (fetchedOption[0]) {
          setSelectedUnit(fetchedOption[0]);
          // setunit_ids(fetchedOption[0].value);
          // setunit_name(fetchedOption[0].label);
        }
      }
    };
    fetchUnitOptions();
  }, [GetUnit]);

  useEffect(() => {
    async function fetchData() {
      if (
        userID &&
        userToken &&
        store_id_param &&
        store_id_param !== "" &&
        oDatetime &&
        oDatetime !== ""
      ) {
        await getAccNamesAssetCash(store_id_param, userID, userToken)
          .then((resp) => {
            if (resp.data.length > 0) {
              const filteredAccTos = resp.data.filter(
                (item) => new Date(item.open_datetime) <= new Date(oDatetime)
              );
              setGetacc_tos(filteredAccTos);
            }
            // setGetacc_tos(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [store_id_param, oDatetime, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    if (getacc_tos.length > 0) {
      const acc = getacc_tos.find((item) => item.name === acc_to);
      //console.log(acc);
      // setacc_to_bal(acc.end_balance);
      if (acc) {
        setacc_to_id(acc.account_id);
      } else {
        setacc_to_id(getacc_tos[0].account_id);
        setacc_to(getacc_tos[0].name);
      }
    } else {
      setacc_to_id(null);
    }
  }, [acc_to, getacc_tos]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (so_id_param && userID && userToken) {
        if (localStorage.getItem("SpecOrder_Tag")) {
          const SpecOrder_Tag = localStorage.getItem("SpecOrder_Tag");
          if (SpecOrder_Tag === "Y") {
            //setspec_ord_flag(true);
            localStorage.setItem("SpecOrder_Tag", "N");
          }
        }
        await GetAccToIdByPusOrder(so_id_param, userID, userToken)
          .then((resp) => {
            //console.log(so_id_param);
            //setacc_to_id(resp.data[0].name);
            setacc_from_bal(resp.data[0].end_balance);
            if (resp.data[0].name === "N/A") {
              setacc_to("");
            } else {
              setacc_to(resp.data[0].name);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });

        // await GetAllVendorsName()
        //   .then((resp) => {
        //     setGetVendor(resp.data || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });
        let a = null;
        await GetPurchaseOrderVendorByID(so_id_param, userID, userToken)
          .then((resp) => {
            // console.log(resp.data[0].vendor_id);
            a = resp.data[0].vendor_id;
            setVendor_ids(resp.data[0].vendor_id);
            setcusFlag(
              // resp.data[0].vendor_id + " " +
              resp.data[0].name
            );
            setstore_id_param(resp.data[0].store_id);
            setInvoice_No(resp.data[0].vendor_invoice_no);
            settrackingno(resp.data[0].tracking_no);
            setshipmethod(resp.data[0].ship_method);
            setprojectname(resp.data[0].recv_by);
            //setstatus_id(resp.data[0].status_id);
            setprojectname(resp.data[0].recv_by);
            setamount_paid(resp.data[0].amount_paid);
            setamount_pend(resp.data[0].amount_pend);
            setacc_from_id(resp.data[0].account_id);
            setppo_id(resp.data[0].po_id);
            setpaym(resp.data[0].payment);
            // console.log(resp.data[0].po_id);
            settax(resp.data[0].tax);
            if (resp.data[0].status_id === 73) {
              setOrderStatus("MARK AS OPEN");
              setorderstatuslable("CLOSE");
            } else {
              setOrderStatus("MARK AS CLOSE");
              setorderstatuslable("OPEN");
            }
            var d1 = new Date(resp.data[0].order_date);
            d1.setMinutes(d1.getMinutes() - d1.getTimezoneOffset());
            setODatetime(d1.toISOString().slice(0, 19));

            setnote(resp.data[0].po_note);
            return a;
          })
          .then((vendorId) => {
            // console.log(vendorId);
            setcartList([]);
          })
          .catch((err) => {
            console.log(err.message);
          });
        // setcartList([]);
        // console.log(a)
        // getProductsAll(a);
        //   getProductsAll();
        await GetPurchaseOrderDetailsByID(so_id_param, userID, userToken).then(
          function (result) {
            //console.log(result.data);
            if (result.data) {
              const productList = result.data.map((item) => ({
                product_id: item.product_id,
                item_no: item.item_no,
                name: item.product_name,
                code: item.code,
                unit_price: item.unit_price,
                quantity: item.quantity,
                discount: item.discount,
                total:
                  item.quantity * item.unit_price -
                  item.quantity * item.discount,
                receive: item.product_received,
                image: item.image,
                notes: item.item_note,
                details: item.details,
              }));
              //console.log(productList);
              // const productIdsInCart = CardList.map(
              //   (product) => product.product_id
              // );
              // setProductOptions((prevOptions) =>
              //   prevOptions.filter(
              //     (option) => !productIdsInCart.includes(option.value)
              //   )
              // );
              // setProductOptions([...filteredProductOptions]);

              setcartList(() => [...productList]);

              const qtyShippedList = productList.map((product) => ({
                product_id: product.product_id,
                receive: product.receive,
                unit_price: product.unit_price,
                discount: product.discount,
                item_no: product.item_no,
                notes: product.notes,
              }));

              setqtyShipped(() => [...qtyShippedList]);

              // const unshippedSet = new Set();

              // productList.forEach((product, index) => {
              //   if (product.receive > 0) {
              //     unshippedSet.add(product.product_id);
              //   }
              // });
              // const unshippedArray = Array.from(unshippedSet);
              // setunshippedList(unshippedArray);

              //console.log(unshippedArray);
            }
          }
        );
      }
    }
    fetchData();
  }, [so_id_param, userID, userToken]);

  // useEffect(() => {
  //   const fetchProductOptions = async () => {
  //     const fetchedProductOptions = GetProduct.map((item) => ({
  //       label: `${item.code} ${item.productname}`,
  //       value: item.product_id,
  //     }));

  //     setProductOptions(fetchedProductOptions);
  //     const productIdsInCart = CardList.map((product) => product.product_id);
  //     setProductOptions((prevOptions) =>
  //       prevOptions.filter((option) => !productIdsInCart.includes(option.value))
  //     );
  //   };
  //   fetchProductOptions();
  // }, [GetProduct, Vendor_ids]);

  // useEffect(() => {
  //   const fetchVendorOptions = async () => {
  //     const fetchedVendorOption = GetVendor.map((item) => ({
  //       label: `${item.name}`,
  //       value: item.vendor_id,
  //     }));
  //     setVendorOptions(fetchedVendorOption);
  //     //setcartList([]);
  //   };
  //   fetchVendorOptions();
  // }, [GetVendor]);

  useEffect(() => {
    CalculateAllFields();
    //console.log(cusFlag);
    //setSelectedVendor(cusFlag);
    // const productIdsInCart = CardList.map((product) => product.product_id);
    // setProductOptions((prevOptions) =>
    //   prevOptions.filter((option) => !productIdsInCart.includes(option.value))
    // );
  }, [CardList, tax]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      {isPageFrozen && <div className="overlay-freeze" />}
      <Container fluid className="g-0 p-0 justify-end">
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{ padding: "0" }}
        >
          <Col md={7} className="container-col">
            <Header
              title={
                ppo_id ? "EDIT SPECIAL PURCHASE ORDER" : "EDIT PURCHASE ORDER"
              }
            />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px" }}>
                Purchase Order DateTime:
              </span>
            </label>
            <input
              disabled
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: "lightgray",
              }}
              value={oDatetime}
            />
          </Col>
          <Col md={3} className="container-col">
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
              }}
            >
              ORDER STATUS: {orderstatuslable}
            </label>
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                // textAlign: "right",
              }}
            >
              PO# {so_id_param}
            </label>
          </Col>
        </Row>
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={9} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <label
                    className="label"
                    htmlFor="SupplierSelect"
                    style={{ fontSize: "18px" }}
                  >
                    Supplier:
                  </label>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <input
                        className="input w-100"
                        style={{ height: "36px" }}
                        type="text"
                        value={cusFlag}
                        readOnly
                        disabled
                      />
                    </div>
                    <div
                      className="sale-bal ml-4"
                      style={{ backgroundColor: currentColor }}
                    >
                      <label>{formatCurrency(acc_from_bal)}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              {!ppo_id && (
                <>
                  <br />
                  <Row
                    xs={1}
                    sm={1}
                    className="justify-content-center"
                    style={{
                      padding: "0",
                    }}
                  >
                    <Col md={12} className="container-col">
                      <div style={{ display: "flex" }}>
                        <label
                          htmlFor="ProductSelect"
                          className="label"
                          style={{ fontSize: "18px", flex: "50%" }}
                        >
                          Product:
                        </label>
                        <label
                          className="label"
                          style={{ fontSize: "18px", flex: "14.5%" }}
                        >
                          Unit:
                        </label>
                        <label
                          className="label"
                          style={{ fontSize: "18px", flex: "12%" }}
                        >
                          Quantity:
                        </label>
                        <label
                          className="label"
                          style={{ fontSize: "18px", flex: "23.5%" }}
                        >
                          Price:
                        </label>
                      </div>
                      <div className="sale-input-div">
                        <div className="sale-input">
                          <Select
                            className="myreact-select-prod"
                            inputId="ProductSelect"
                            menuPlacement="bottom"
                            menuPosition="fixed"
                            value={selectedProduct}
                            onChange={handleChangeProduct}
                            options={productOptions}
                            isSearchable
                            placeholder="Search Product With Name / Code"
                            isClearable
                            styles={customStyles}
                            // onKeyDown={(event) =>
                            //   handleChangeProduct1(selectedProduct, event)
                            // }
                            filterOption={customFilter}
                            onInputChange={handleInputChange}
                          />
                          <Select
                            className="myreact-select-unit"
                            inputId="unit"
                            menuPlacement="bottom"
                            menuPosition="fixed"
                            value={selectedUnit}
                            onChange={handleChangeUnit}
                            options={UnitOptions}
                            isSearchable
                            placeholder="Unit"
                            // isClearable
                            styles={customStyles}
                            onKeyDown={(event) =>
                              handleChangeUnit1(selectedUnit, event)
                            }
                          />
                          <input
                            id="qty"
                            type="number"
                            step="1"
                            min="0"
                            max="1000000"
                            className="input myreact-select-prod-qty"
                            //defaultValue={1.0}
                            value={qty}
                            placeholder="Quantity"
                            onClick={() => handleInputClick("qty", -8)}
                            onChange={handleChangeQty}
                            onKeyDown={handleEnterQty}
                          />
                          <input
                            id="uprice"
                            type="number"
                            step="1"
                            min="0"
                            max="1000000"
                            className="input myreact-select-prod-price"
                            //defaultValue={1.0}
                            value={unitPrice}
                            placeholder="Unit Price"
                            onClick={() => handleInputClick("uprice", -9)}
                            onChange={handleChangeUPrice}
                            onKeyDown={handleEnterUPrice}
                          />
                        </div>
                        <button
                          className="sale-bal ml-4"
                          type="button"
                          style={{
                            backgroundColor: currentColor,
                            fontWeight: "1000",
                            fontSize: "18px",
                            height: "100%",
                          }}
                          onClick={handleAddcartClick}
                        >
                          +
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              <div className="mt-2 right-table-field">
                <label>{selectedProduct?.last_so_info}</label>
              </div>
              <br />
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale"
                style={{ height: ppo_id ? "55vh" : "44vh" }}
              >
                <div className="m-0 p-0">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-sale-tr">
                        <th style={{ width: "85px" }}>CODE</th>
                        <th style={{ width: "320px" }}>PRODUCT</th>
                        <th style={{ width: "60px" }}>UNIT</th>
                        <th style={{ width: "65px" }}>QTY</th>
                        <th style={{ width: "80px" }}>PRICE</th>
                        <th style={{ width: "75px" }}>DISC</th>
                        <th style={{ width: "75px" }}>TOTAL</th>
                        {/* <th style={{ width: "65px" }}>RCV QTY</th> */}
                        <th style={{ width: "280px" }}>NOTES</th>
                        <th style={{ width: "35px" }}>DEL</th>
                      </tr>
                    </thead>
                    <tbody className="pos-table-body">
                      {CardList?.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleToggle(item)}>
                            {item.code}
                          </td>
                          <td onClick={() => handleToggle(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.image && (
                                <div
                                  className="image flex gap-4"
                                  style={{
                                    maxWidth: "72px",
                                    height: "72px",
                                    flex: "0 0 20%",
                                  }}
                                >
                                  <img
                                    className="rounded-xl"
                                    src={`data:image/jpeg;base64,${item.image}`}
                                    alt={`Product ${item.code}`}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: item.image ? "0 0 80%" : "0 0 100%",
                                  paddingTop: !item.image && "8px",
                                  paddingBottom: !item.image && "7px",
                                  alignSelf: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {truncate(item.name, item.image ? 38 : 44)}
                                </div>
                                <div>
                                  {truncate(item.details, item.image ? 78 : 88)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>{item.unit}</td>
                          <td>
                            <div className="centered-input">
                              <input
                                disabled={ppo_id}
                                className="input"
                                id="quantity"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.quantity}
                                onClick={() =>
                                  handleInputClick("quantity", index)
                                }
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="unit_price"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.unit_price}
                                onClick={() =>
                                  handleInputClick("unit_price", index)
                                }
                                onChange={(e) =>
                                  handleUnitPriceChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="discount"
                                min="0"
                                max={item.unit_price}
                                type="number"
                                step="1.00"
                                value={item.discount}
                                onClick={() =>
                                  handleInputClick("discount", index)
                                }
                                onChange={(e) =>
                                  handleDiscountChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>{formatCurrency(item.total)}</td>
                          {/* <td>
                            <div className="centered-input">
                              <input
                                disabled={ppo_id}
                                className="input"
                                id="receive"
                                type="number"
                                min="0"
                                max={item.quantity}
                                step="1.00"
                                value={item.receive}
                                onClick={() =>
                                  handlereceiveClick("receive", index)
                                }
                                onChange={(e) =>
                                  handlereceiveChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td> */}
                          <td>
                            <div className="centered-input">
                              <textarea
                                disabled={ppo_id}
                                className="textarea"
                                id="notes"
                                type="text"
                                maxLength={250}
                                value={item.notes}
                                onClick={() => handleInputClick("notes", index)}
                                onChange={(e) =>
                                  handleNotesChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <button onClick={() => handleDeleteClick(index)}>
                              ❌
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale1"
              >
                <table className="borderless w-100 h-100">
                  <tbody>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ paddingTop: "8px" }}
                      >
                        SUB TOTAL:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          paddingTop: "8px",
                        }}
                      >
                        {formatCurrency(total_amount)}
                      </td>
                      <td
                        className="table-grand-sum"
                        rowSpan="6"
                        style={{ backgroundColor: currentColor }}
                      >
                        <div className="tgs-label">
                          BALANCE DUE
                          <br />
                          <span className="tgs-value">
                            {formatCurrency(amount_pend)}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">DISCOUNT:</td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                        }}
                      >
                        {formatCurrency(totaldiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">AMOUNT PAID:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          disabled={paym === 1 ? true : false}
                          value={amount_paid}
                          onChange={handleChangeAmountPaid}
                          onClick={() => handleInputClick("amount_paid", -4)}
                          className="input table-sum-tb"
                          style={{
                            marginLeft: "13px",
                            color: currentColor,
                            background: paym === 1 && "lightgray",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">PAID FROM:</td>
                      <td className="table-sum-cash">
                        <button
                          style={{
                            verticalAlign: "middle",
                            fontSize: "calc(0.4vw + 11px)",
                          }}
                          onClick={handlePaymentToggle}
                        >
                          <FcInfo />
                        </button>
                        <select
                          className="select table-sum-tb"
                          disabled={paym === 1 ? true : false}
                          style={{
                            color: currentColor,
                            marginLeft: "3px",
                            background: paym === 1 && "lightgray",
                          }}
                          value={acc_to}
                          onChange={handleAccToChange}
                        >
                          {getacc_tos.map((item) => (
                            <option key={item.account_id}>{item.name}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">GRAND TOTAL:</td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                        }}
                      >
                        {formatCurrency(grandtotal)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ marginBottom: "8px" }}
                      >
                        TOTAL ITEMS:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          marginBottom: "8px",
                        }}
                      >
                        {total_item || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </Col>
          <Col md={3} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleSaleOrderClick}
                  >
                    <div className="action-btn">SAVE</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: "gray",
                      // background: currentColor,
                    }}
                    // onClick={handlePrintOrderClick}
                  >
                    <div className="action-btn">PRINT</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleNewClick}
                  >
                    <div className="action-btn">NEW</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleClearClick}
                  >
                    <div className="action-btn">CLEAR</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleStatusClick}
                  >
                    <div className="action-btn">{OrderStatus}</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleBackClick}
                  >
                    <div className="action-btn">CLOSE</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Col md={6} className="container-col right-table-field">
                  <label>Ship Method:</label>
                  <input
                    type="text"
                    name="shipmethod"
                    placeholder="Ship-Method"
                    value={shipmethod}
                    onChange={handleChangeShipMethod}
                    className="input"
                  />
                </Col>
                <Col md={6} className="container-col right-table-field">
                  <label>Tracking No:</label>
                  <input
                    type="text"
                    name="tracking_no"
                    value={trackingno}
                    onChange={handleChangeTrackingNo}
                    placeholder="Tracking No."
                    className="input"
                  />
                </Col>
                <Col md={6} className="container-col right-table-field">
                  <label>Supplier Inv No:</label>
                  <input
                    type="text"
                    name="Invoice No"
                    value={Invoice_No}
                    onChange={handleChangeInvNo}
                    placeholder="Supplier Invoive No."
                    className="input"
                  />
                </Col>
                <Col md={6} className="container-col right-table-field">
                  {/* <label>Recv By:</label>
                  <input
                    type="text"
                    name="project name"
                    value={projectname}
                    onChange={handleChangeProjectName}
                    placeholder="Receive By"
                    className="input"
                  /> */}
                </Col>
                <Col
                  md={12}
                  className="container-col right-table-field"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "16px",
                  }}
                >
                  <textarea
                    placeholder="Purchase Order Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                  />
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{ padding: "0px" }}
              >
                {keypadButtons.map((number, index) => (
                  <Col md={4} className="container-col" key={index}>
                    <Card
                      className="keypad-button1"
                      style={{
                        border: "1px solid " + currentColor,
                        color: currentColor,
                      }}
                      key={number}
                      onClick={() => handleKeypadClick(number)}
                    >
                      {number}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* {toggle && (
            <div className="overlay1">
              <Sidebar
                close={() => setToggle(false)}
                product_id={productID}
                store_id={store_id_param}
              />
            </div>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
export default EditPurchaseOrder;
