import React, { useState, useEffect } from "react";
import {
  GetAccLedgerReport,
  GetAccLedgerSummaryReport,
  GetAccountListforRep,
  GetAllAccountCategories,
  GetAllAccountTypes,
  GetAllBrands,
  GetAllCategories,
  GetAllStores,
  GetCustomerList,
  GetInvLedReport,
  GetInvPrfReport,
  GetInvStkReport,
  GetProductListforRep,
  GetPurchaseDetailReport,
  GetPurchaseSummaryReport,
  GetSaleDetailReport,
  GetSaleSummaryReport,
  GetSummaryReport,
  GetUserList,
  GetVendorList,
} from "../../api";
import { Col, Container, Row } from "react-bootstrap";
import { useStateContext } from "../../contexts/ContextProvider";
import { Header, Button } from "../../components";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const [pdfURL, setPdfURL] = useState(null);
  const { currentColor, storeG, userID, userToken } = useStateContext();
  const navigate = useNavigate();

  const [sDatetime, setSDatetime] = useState("");
  const [sMinDatetime, setSMinDatetime] = useState("");
  const [sMaxDatetime, setSMaxDatetime] = useState("");
  const [eDatetime, setEDatetime] = useState("");
  const [eMinDatetime, setEMinDatetime] = useState("");
  const [eMaxDatetime, setEMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState("");
  const [productBrands, setProductBrands] = useState([]);
  const [productBrand, setProductBrand] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [productsAll, setProductsAll] = useState([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [accountAllCategories, setAccountAllCategories] = useState([]);
  const [accountCategories, setAccountCategories] = useState([]);
  const [accountCategory, setAccountCategory] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");

  const [invStk, setInvStk] = useState(false);
  const [invLed, setInvLed] = useState(false);
  const [invPrf, setInvPrf] = useState(false);
  const [accLed, setAccLed] = useState(false);
  const [aLedSm, setALedSm] = useState(false);
  const [summar, setSummar] = useState(false);
  const [salDet, setSalDet] = useState(false);
  const [salSum, setSalSum] = useState(false);
  const [purDet, setPurDet] = useState(false);
  const [purSum, setPurSum] = useState(false);

  const [title, setTitle] = useState("");
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(true);

  const fetchAndDisplayPDF = async () => {
    TimeoutUtility.resetTimeout();
    if (userID && userToken) {
      var sDt = sDatetime.replace("T", " ");
      var accc = 0,
        stre = 0;
      var sDstr = -1,
        sDcus = -1,
        sDsup = -1,
        sDusr = -1,
        sDprd = -1;
      var eDt = null;
      if (dTNow) {
        var d = new Date();
        eDt =
          [
            d.getFullYear(),
            (d.getMonth() + 1).toString().padStart(2, "0"),
            d.getDate().toString().padStart(2, "0"),
          ].join("-") +
          " " +
          [
            d.getHours().toString().padStart(2, "0"),
            d.getMinutes().toString().padStart(2, "0"),
            d.getSeconds().toString().padStart(2, "0"),
          ].join(":");
      } else {
        eDt = eDatetime.replace("T", " ");
      }

      if (invStk) {
        //console.log(sDt, eDt, store);
        trackPromise(
          Promise.all([
            GetInvStkReport(sDt, eDt, store || 0, userID, userToken),
          ])
            .then(([result]) => {
              setloading(true);
              //console.log(result);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (invLed) {
        if (!product || product === 0 || product === "0") {
          alert("Select a Product to generate this Report!");
        } else {
          trackPromise(
            Promise.all([
              GetInvLedReport(sDt, eDt, product, store || 0, userID, userToken),
            ])
              .then(([result]) => {
                setloading(true);
                //console.log(result);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      } else if (invPrf) {
        if (!product || product === 0 || product === "0") {
          alert("Select a Product to generate this Report!");
        } else {
          trackPromise(
            Promise.all([
              GetInvPrfReport(sDt, eDt, product, store || 0, userID, userToken),
            ])
              .then(([result]) => {
                setloading(true);
                //console.log(result);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      } else if (accLed) {
        if (
          store &&
          account &&
          store !== 0 &&
          account !== 0 &&
          store !== "0" &&
          account !== "0"
        ) {
          accc = account;
          stre = store;
        } else if (
          store &&
          accountCategory &&
          store !== 0 &&
          accountCategory !== 0 &&
          store !== "0" &&
          accountCategory !== "0"
        ) {
          accc = accountCategory;
          stre = store;
        } else if (
          accountCategory &&
          accountCategory !== 0 &&
          accountCategory !== "0"
        ) {
          accc = accountCategory;
          stre = -1;
        } else if (
          store &&
          accountType &&
          store !== 0 &&
          accountType !== 0 &&
          store !== "0" &&
          accountType !== "0"
        ) {
          accc = accountType;
          stre = store;
        } else if (accountType && accountType !== 0 && accountType !== "0") {
          accc = accountType;
          stre = -1;
        } else if (store && store !== 0 && store !== "0") {
          accc = -1;
          stre = store;
        } else {
          accc = -1;
          stre = -1;
        }

        if (accc !== 0 && stre !== 0) {
          // console.log(sDt, eDt, stre, accc);
          trackPromise(
            Promise.all([
              GetAccLedgerReport(sDt, eDt, stre, accc, userID, userToken),
            ])
              .then(([result]) => {
                setloading(true);
                //console.log(result);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      } else if (aLedSm) {
        if (
          store &&
          account &&
          store !== 0 &&
          account !== 0 &&
          store !== "0" &&
          account !== "0"
        ) {
          accc = account;
          stre = store;
        } else if (
          store &&
          accountCategory &&
          store !== 0 &&
          accountCategory !== 0 &&
          store !== "0" &&
          accountCategory !== "0"
        ) {
          accc = accountCategory;
          stre = store;
        } else if (
          accountCategory &&
          accountCategory !== 0 &&
          accountCategory !== "0"
        ) {
          accc = accountCategory;
          stre = -1;
        } else if (
          store &&
          accountType &&
          store !== 0 &&
          accountType !== 0 &&
          store !== "0" &&
          accountType !== "0"
        ) {
          accc = accountType;
          stre = store;
        } else if (accountType && accountType !== 0 && accountType !== "0") {
          accc = accountType;
          stre = -1;
        } else if (store && store !== 0 && store !== "0") {
          accc = -1;
          stre = store;
        } else {
          accc = -1;
          stre = -1;
        }

        if (accc !== 0 && stre !== 0) {
          trackPromise(
            Promise.all([
              GetAccLedgerSummaryReport(
                sDt,
                eDt,
                stre,
                accc,
                userID,
                userToken
              ),
            ])
              .then(([result]) => {
                setloading(true);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      } else if (salDet) {
        if (store && store !== 0 && store !== "0") {
          sDstr = store;
        }
        if (product && product !== 0 && product !== "0") {
          sDprd = product;
        }
        if (customer && customer !== 0 && customer !== "0") {
          sDcus = customer;
        }
        if (supplier && supplier !== 0 && supplier !== "0") {
          sDsup = supplier;
        }
        if (user && user !== 0 && user !== "0") {
          sDusr = user;
        }
        trackPromise(
          Promise.all([
            GetSaleDetailReport(
              sDt,
              eDt,
              sDstr,
              sDprd,
              sDcus,
              sDsup,
              sDusr,
              userID,
              userToken
            ),
          ])
            .then(([result]) => {
              setloading(true);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (salSum) {
        if (store && store !== 0 && store !== "0") {
          sDstr = store;
        }
        if (product && product !== 0 && product !== "0") {
          sDprd = product;
        }
        if (customer && customer !== 0 && customer !== "0") {
          sDcus = customer;
        }
        if (supplier && supplier !== 0 && supplier !== "0") {
          sDsup = supplier;
        }
        if (user && user !== 0 && user !== "0") {
          sDusr = user;
        }
        trackPromise(
          Promise.all([
            GetSaleSummaryReport(
              sDt,
              eDt,
              sDstr,
              sDprd,
              sDcus,
              sDsup,
              sDusr,
              userID,
              userToken
            ),
          ])
            .then(([result]) => {
              setloading(true);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (purDet) {
        if (store && store !== 0 && store !== "0") {
          sDstr = store;
        }
        if (product && product !== 0 && product !== "0") {
          sDprd = product;
        }
        if (supplier && supplier !== 0 && supplier !== "0") {
          sDsup = supplier;
        }
        if (user && user !== 0 && user !== "0") {
          sDusr = user;
        }
        trackPromise(
          Promise.all([
            GetPurchaseDetailReport(
              sDt,
              eDt,
              sDstr,
              sDprd,
              sDsup,
              sDusr,
              userID,
              userToken
            ),
          ])
            .then(([result]) => {
              setloading(true);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (purSum) {
        if (store && store !== 0 && store !== "0") {
          sDstr = store;
        }
        if (product && product !== 0 && product !== "0") {
          sDprd = product;
        }
        if (supplier && supplier !== 0 && supplier !== "0") {
          sDsup = supplier;
        }
        if (user && user !== 0 && user !== "0") {
          sDusr = user;
        }
        trackPromise(
          Promise.all([
            GetPurchaseSummaryReport(
              sDt,
              eDt,
              sDstr,
              sDprd,
              sDsup,
              sDusr,
              userID,
              userToken
            ),
          ])
            .then(([result]) => {
              setloading(true);
              const pdfBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              const pdfURL = URL.createObjectURL(pdfBlob);
              setPdfURL(pdfURL);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      } else if (summar) {
        if (!store || store === 0 || store === "0") {
          alert("Select a Store to continue!");
        } else if (store && store !== 0 && store !== "0") {
          trackPromise(
            Promise.all([
              GetSummaryReport(sDt, eDt, store || 0, userID, userToken),
            ])
              .then(([result]) => {
                setloading(true);
                //console.log(result);
                const pdfBlob = new Blob([result.data], {
                  type: "application/pdf",
                });
                const pdfURL = URL.createObjectURL(pdfBlob);
                setPdfURL(pdfURL);
              })
              .catch((err) => {
                console.log(err.message);
              })
              .finally(() => {
                setloading(false);
              })
          );
        }
      }
    }
  };

  const handleDownloadPDF = () => {
    TimeoutUtility.resetTimeout();
    if (pdfURL) {
      const link = document.createElement("a");
      link.href = pdfURL;
      link.download = invStk
        ? `InventoryReport.pdf`
        : invLed
        ? `InventoryLedgerReport.pdf`
        : invPrf
        ? `ItemProfitLossReport.pdf`
        : accLed
        ? `AccountLedgerReport.pdf`
        : aLedSm
        ? `AccountLedgerSummaryReport.pdf`
        : salDet
        ? `SalesDetailedReport.pdf`
        : salSum
        ? `SalesSummaryReport.pdf`
        : purDet
        ? `PurchaseDetailedReport.pdf`
        : purSum
        ? `PurchaseSummaryReport.pdf`
        : summar && `SummaryReport.pdf`;
      link.click();
    }
  };

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setEDatetime(now.toISOString().slice(0, 19));
    setSMaxDatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setEMaxDatetime(now.toISOString().slice(0, 19));
      //now = new Date(now.getFullYear(), 0, 2);
      setEMinDatetime(sDatetime);
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeSDateTime = (e) => {
    if (e === "undefined") {
      setSDatetime(null);
    } else {
      if (
        new Date(e) > new Date(sMinDatetime) &&
        new Date(e) < new Date(sMaxDatetime)
      ) {
        setSDatetime(e);
        setEMinDatetime(e);
      }
    }
  };

  const handleChangeEDateTime = (e) => {
    if (e === "undefined") {
      setEDatetime(null);
    } else {
      if (
        new Date(e) > new Date(eMinDatetime) &&
        new Date(e) < new Date(eMaxDatetime)
      ) {
        setEDatetime(e);
        setSMaxDatetime(e);
      }
    }
  };

  const handleChangeStore = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setStore("");
    } else {
      setStore(e.target.value);
    }
  };

  const handleChangeProductBrand = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setProductBrand("");
    } else {
      setProductBrand(e.target.value);
    }
    const filtprod = productsAll.filter(
      (item) =>
        (e.target.value === "0" ||
          item.brand_id.toString() === e.target.value) &&
        (productCategory === "" ||
          item.category_id.toString() === productCategory)
    );
    setProducts(filtprod);
    setProduct("");
  };

  const handleChangeProductCategory = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setProductCategory("");
    } else {
      setProductCategory(e.target.value);
    }
    const filtprod = productsAll.filter(
      (item) =>
        (e.target.value === "0" ||
          item.category_id.toString() === e.target.value) &&
        (productBrand === "" || item.brand_id.toString() === productBrand)
    );
    setProducts(filtprod);
    setProduct("");
  };

  const handleChangeProduct = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setProduct("");
    } else {
      setProduct(e.target.value);
    }
  };

  const handleChangeAccountType = (e) => {
    TimeoutUtility.resetTimeout();
    console.log(e.target.value);
    if (e.target.value === 0 || e.target.value === "0") {
      setAccountType("");
      setAccount("");
      setAccountCategories([]);
    } else {
      setAccountType(e.target.value);
      const filtaccCatg = accountAllCategories.filter(
        (cat) => cat.type_id.toString() === e.target.value
      );
      setAccountCategory("");
      setAccountCategories(filtaccCatg);
      const filtacc = accountsAll.filter(
        (cat) => cat.type_id.toString() === e.target.value
      );
      setAccounts(filtacc);
    }
  };

  const handleChangeAccountCategory = (e) => {
    TimeoutUtility.resetTimeout();
    console.log(e.target.value, accountType);
    if (e.target.value === 0 || e.target.value === "0") {
      setAccountCategory("");
      setAccount("");
      // console.log(e.target.value, accountType);
      if (accountType && accountType !== 0) {
        // console.log(e.target.value, accountType);
        const filtacc = accountsAll.filter(
          (cat) => cat.type_id.toString() === accountType
        );
        // console.log(filtacc);
        setAccounts(filtacc);
      }
    } else {
      setAccountCategory(e.target.value);
      const filtacc = accountsAll.filter(
        (cat) => cat.category_id.toString() === e.target.value
      );
      setAccounts(filtacc);
    }
  };

  const handleChangeAccount = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setAccount("");
    } else {
      setAccount(e.target.value);
    }
  };

  const handleChangeCustomer = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setCustomer("");
    } else {
      setCustomer(e.target.value);
    }
  };

  const handleChangeSupplier = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setSupplier("");
    } else {
      setSupplier(e.target.value);
    }
  };

  const handleChangeUser = (e) => {
    TimeoutUtility.resetTimeout();
    if (e.target.value === 0 || e.target.value === "0") {
      setUser("");
    } else {
      setUser(e.target.value);
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    if (accLed || aLedSm) {
      try {
        navigate("/Account");
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (invStk || invLed || invPrf) {
      try {
        navigate("/Inventory");
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (salDet || salSum) {
      try {
        navigate("/Sales");
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (purDet || purSum) {
      try {
        navigate("/Purchase");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      TimeoutUtility.resetTimeout();
      if (
        (accLed || aLedSm) &&
        userID &&
        userToken &&
        store &&
        store !== 0 &&
        store !== "0"
      ) {
        await GetAccountListforRep(store, userID, userToken)
          .then((resp) => {
            setAccount("");
            setAccountsAll(resp.data || []);
            // console.log(resp.data);
            if (accountType && accountType !== 0) {
              // console.log(accountType, accountCategory);
              if (accountCategory && accountCategory !== 0) {
                const filtacc = resp.data.filter(
                  (cat) => cat.category_id.toString() === accountCategory
                );
                // console.log(filtacc);
                setAccounts(filtacc);
              } else {
                const filtacc = resp.data.filter(
                  (cat) => cat.type_id.toString() === accountType
                );
                // console.log(filtacc);
                setAccounts(filtacc);
              }
            } else {
              setAccounts(resp.data || []);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (
        (invLed || invPrf) &&
        userID &&
        userToken &&
        store &&
        store !== 0 &&
        store !== "0"
      ) {
        await GetProductListforRep(store, userID, userToken)
          .then((resp) => {
            setProduct("");
            setProductsAll(resp.data || []);
            const filtprod = resp.data.filter(
              (item) =>
                (productCategory === "" ||
                  item.category_id.toString() === productCategory) &&
                (productBrand === "" ||
                  item.brand_id.toString() === productBrand)
            );
            setProducts(filtprod);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (
        (salDet || salSum) &&
        userID &&
        userToken &&
        store &&
        store !== 0 &&
        store !== "0"
      ) {
        await GetProductListforRep(store, userID, userToken)
          .then((resp) => {
            setProduct("");
            setProducts(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        await GetCustomerList(store, userID, userToken)
          .then((resp) => {
            setCustomer("");
            setCustomers(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        await GetVendorList(store, userID, userToken)
          .then((resp) => {
            setSupplier("");
            setSuppliers(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (
        (purDet || purSum) &&
        userID &&
        userToken &&
        store &&
        store !== 0 &&
        store !== "0"
      ) {
        await GetProductListforRep(store, userID, userToken)
          .then((resp) => {
            setProduct("");
            setProducts(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        await GetVendorList(store, userID, userToken)
          .then((resp) => {
            setSupplier("");
            setSuppliers(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else if (store === 0 || store === "0") {
        setAccount("");
        setProduct("");
        setAccounts([]);
        setProducts([]);
      }
    }
    fetchData();
  }, [store]);

  useEffect(() => {
    async function fetchData() {
      if (stores?.length > 0 && storeG != null && storeG.store_id !== 0) {
        const storeid = stores.find(
          (item) => item.store_id === storeG.store_id
        );
        if (storeid) {
          setStore(storeid.store_id);
        }
      }
    }
    fetchData();
  }, [stores, storeG]);

  useEffect(() => {
    async function fetchData() {
      TimeoutUtility.resetTimeout();
      if (userID && userToken) {
        let tmp = window.location.href.slice(
          window.location.href.lastIndexOf("/") + 1,
          window.location.href.length
        );
        //console.log(tmp);
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setEDatetime(now.toISOString().slice(0, 19));
        setSMaxDatetime(now.toISOString().slice(0, 19));
        now = new Date(now.getFullYear(), now.getMonth(), 2);
        setSDatetime(now.toISOString().slice(0, 11) + "00:00:00");
        setEMinDatetime(now.toISOString().slice(0, 11) + "00:00:00");
        now = new Date(now.getFullYear() - 5, 0, 2);
        setSMinDatetime(now.toISOString().slice(0, 11) + "00:00:00");

        if (tmp === "InventoryStockReport") {
          setInvStk(true);
          setTitle("Inventory Stock Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "InventoryLedgerReport") {
          setInvLed(true);
          setTitle("Inventory Ledger Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllBrands(userID, userToken)
            .then((resp) => {
              setProductBrands(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllCategories(userID, userToken)
            .then((resp) => {
              setProductCategories(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "ItemProfitLossReport") {
          setInvPrf(true);
          setTitle("Item Profit / Loss Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllBrands(userID, userToken)
            .then((resp) => {
              setProductBrands(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllCategories(userID, userToken)
            .then((resp) => {
              setProductCategories(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "LedgerReport") {
          setAccLed(true);
          setTitle("Account Ledger Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllAccountTypes(userID, userToken)
            .then((resp) => {
              setAccountTypes(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllAccountCategories(userID, userToken)
            .then((resp) => {
              setAccountAllCategories(resp.data || []);
              setAccountCategories([]);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "LedgerSummaryReport") {
          setALedSm(true);
          setTitle("Account Ledger Summary Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllAccountTypes(userID, userToken)
            .then((resp) => {
              setAccountTypes(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetAllAccountCategories(userID, userToken)
            .then((resp) => {
              setAccountAllCategories(resp.data || []);
              setAccountCategories([]);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "SalesDetailReport") {
          setSalDet(true);
          setTitle("Sales Detailed Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetUserList(userID, userToken)
            .then((resp) => {
              setUsers(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "SalesSummaryReport") {
          setSalSum(true);
          setTitle("Sales Summary Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetUserList(userID, userToken)
            .then((resp) => {
              setUsers(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "PurchaseDetailReport") {
          setPurDet(true);
          setTitle("Purchase Detailed Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetUserList(userID, userToken)
            .then((resp) => {
              setUsers(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "PurchaseSummaryReport") {
          setPurSum(true);
          setTitle("Purchase Summary Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
          await GetUserList(userID, userToken)
            .then((resp) => {
              setUsers(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (tmp === "SummaryReport") {
          setSummar(true);
          setTitle("Summary Report");
          await GetAllStores(userID, userToken)
            .then((resp) => {
              setStores(resp.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Report" title={title} />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {(invStk ||
            invLed ||
            invPrf ||
            accLed ||
            aLedSm ||
            summar ||
            salDet ||
            salSum ||
            purDet ||
            purSum) && (
            <Col md="auto">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Date From:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <input
                  className="select-custom-rep rounded-xl"
                  id="s_date"
                  type="datetime-local"
                  value={sDatetime}
                  min={sMinDatetime}
                  max={sMaxDatetime}
                  onChange={(e) => handleChangeSDateTime(e.target.value)}
                />
              </Row>
            </Col>
          )}
          {(invStk ||
            invLed ||
            invPrf ||
            accLed ||
            aLedSm ||
            summar ||
            salDet ||
            salSum ||
            purDet ||
            purSum) && (
            <Col md="auto">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">
                  <span style={{ paddingRight: "42px" }}>Date To: </span>
                  <span>
                    Now{" "}
                    <input
                      type="checkbox"
                      checked={dTNow === 1}
                      onChange={handleChangeDTNow}
                      style={{
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  </span>
                </label>
              </Row>
              <Row style={{ width: "100%" }}>
                <input
                  disabled={dTNow}
                  className="select-custom-rep rounded-xl"
                  id="e_date"
                  type="datetime-local"
                  style={{
                    background: dTNow && "lightgray",
                  }}
                  value={eDatetime}
                  min={eMinDatetime}
                  max={eMaxDatetime}
                  onChange={(e) => handleChangeEDateTime(e.target.value)}
                />
              </Row>
            </Col>
          )}
          {(invStk ||
            invLed ||
            invPrf ||
            accLed ||
            aLedSm ||
            summar ||
            salDet ||
            salSum ||
            purDet ||
            purSum) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Store:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={store}
                  onChange={handleChangeStore}
                >
                  <option value={0}>{"All Stores"}</option>
                  {stores.map((item, index) => (
                    <option key={index} value={item.store_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(invLed || invPrf) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Product Brand:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={productBrand}
                  onChange={handleChangeProductBrand}
                >
                  <option value={0}>{"All Product Brands"}</option>
                  {productBrands.map((item, index) => (
                    <option key={index} value={item.brand_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(invLed || invPrf) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Product Category:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={productCategory}
                  onChange={handleChangeProductCategory}
                >
                  <option value={0}>{"All Product Categories"}</option>
                  {productCategories.map((item, index) => (
                    <option key={index} value={item.category_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(invLed || invPrf || salDet || salSum || purDet || purSum) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Product:</label>
              </Row>
              <Row style={{ width: "320px" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={product}
                  onChange={handleChangeProduct}
                >
                  <option value={0}>{"All Products"}</option>
                  {products.map((item, index) => (
                    <option key={index} value={item.product_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(accLed || aLedSm) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account Type:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={accountType}
                  onChange={handleChangeAccountType}
                >
                  <option value={0}>{"All Account Types"}</option>
                  {accountTypes.map((item, index) => (
                    <option key={index} value={item.a_type_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(accLed || aLedSm) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account Category:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={accountCategory}
                  onChange={handleChangeAccountCategory}
                >
                  <option value={0}>{"All Account Categories"}</option>
                  {accountCategories.map((item, index) => (
                    <option key={index} value={item.category_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(accLed || aLedSm) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Account:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={account}
                  onChange={handleChangeAccount}
                >
                  <option value={0}>{"All Accounts"}</option>
                  {accounts.map((item, index) => (
                    <option key={index} value={item.account_id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(salDet || salSum) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Customer:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={customer}
                  onChange={handleChangeCustomer}
                >
                  <option value={0}>{"All Customers"}</option>
                  {customers.map((item, index) => (
                    <option key={index} value={item.customer_id}>
                      {item.customer}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(salDet || salSum || purDet || purSum) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">Supplier:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={supplier}
                  onChange={handleChangeSupplier}
                >
                  <option value={0}>{"All Suppliers"}</option>
                  {suppliers.map((item, index) => (
                    <option key={index} value={item.vendor_id}>
                      {item.vendor}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          {(salDet || salSum || purDet || purSum) && (
            <Col md="auto" className="mr-4">
              <Row style={{ width: "100%" }}>
                <label className="label-rep">User:</label>
              </Row>
              <Row style={{ width: "100%" }}>
                <select
                  className="select-custom-rep rounded-xl"
                  value={user}
                  onChange={handleChangeUser}
                >
                  <option value={0}>{"All Users"}</option>
                  {users.map((item, index) => (
                    <option key={index} value={item.user_id}>
                      {item.user}
                    </option>
                  ))}
                </select>
              </Row>
            </Col>
          )}
          <Col md="auto" style={{ paddingRight: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="Update & View"
              borderRadius="10px"
              onClick={fetchAndDisplayPDF}
            />
          </Col>
          <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              disabled={!pdfURL}
              bgColor={!pdfURL ? "gray" : currentColor}
              text="Download"
              borderRadius="10px"
              onClick={handleDownloadPDF}
            />
          </Col>
          {(accLed ||
            aLedSm ||
            invStk ||
            invLed ||
            invPrf ||
            salDet ||
            salSum ||
            purDet ||
            purSum) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                // disabled={!pdfURL}
                bgColor={currentColor}
                text="Back"
                borderRadius="10px"
                onClick={handleBackClick}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading && promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          {pdfURL && (
            <div>
              <iframe
                src={`${pdfURL}#toolbar=0`}
                title="Inventory Report"
                width="80%"
                height="720px"
                style={{
                  border: "1px solid #ddd",
                  marginTop: "24px",
                  margin: "auto",
                  marginBottom: "24px",
                }}
              ></iframe>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Report;
