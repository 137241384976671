import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { GetAccountByID, GetAccountLedgerLast } from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Selection,
  Inject,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const ViewAccount = () => {
  const { currentColor, userID, userToken } = useStateContext();
  const [AccountType, setAccountType] = useState("");
  const [AccountCat, setAccountCat] = useState("");
  const [AccountCode, setAccountCode] = useState("0000");
  const [acc_notes, setAccNotes] = useState("");
  const [acc_name, setAccName] = useState("");
  const [acc_desc, setAccDesc] = useState("");
  //const [acc_id, setacc_id] = useState("");
  //const [ledger_notes, setLedgerNotes] = useState("");
  const [OpeningBal, setOpeningBal] = useState("");
  const [CustLedgs, setCustLedgs] = useState([]);
  const { promiseInProgress } = usePromiseTracker();
  //const [ledger_date, setledger_date] = useState("");
  let param = useParams();

  const navigate = useNavigate();

  const ActsGrid = [
    {
      field: "note",
      headerText: "Ledger Note",
      minWidth: "360",
      width: "440",
      maxWidth: "650",
      textAlign: "left",
    },
    {
      field: "datetime",
      headerText: "DateTime",
      minWidth: "180",
      width: "180",
      maxWidth: "240",
      textAlign: "center",
    },
    {
      field: "credit",
      headerText: "Credit",
      width: "100",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "debit",
      headerText: "Debit",
      width: "100",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "bal",
      headerText: "Balance",
      width: "100",
      format: "C2",
      textAlign: "right",
    },
  ];

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      //console.log("Back");
      navigate("/Account");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken && param.Account_id) {
        GetAccountByID(param.Account_id, userID, userToken)
          .then((resp) => {
            setAccName(resp.data[0].name);
            setAccDesc(resp.data[0].description);
            setAccNotes(resp.data[0].notes);
            setAccountType(resp.data[0].acc_type);
            setAccountCat(resp.data[0].category);
            setAccountCode(resp.data[0].cod);
            const dbDate1 = new Date(resp.data[0].datetime);
            dbDate1.setUTCHours(24);
            //setledger_date(dbDate1.toISOString().split("T")[0]);
            //setacc_id(resp.data[0].account_id);
            setOpeningBal(resp.data[0].end_balance);
            //setLedgerNotes(resp.data[0].note);
          })
          .catch((err) => {
            console.log(err.message);
          });
        trackPromise(
          Promise.all([
            GetAccountLedgerLast(param.Account_id, userID, userToken),
          ])
            .then((resp) => {
              setCustLedgs(resp[0].data);
            })
            .catch((err) => {
              console.log(err.message);
            })
        );
      }
    }
    fetchData();
  }, [userID, userToken, param.Account_id]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="VIEW ACCOUNT" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row xs={1} sm={1} style={{ padding: "0" }}>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Account Code: </label>
                  <input
                    type="text"
                    name="acc_code"
                    placeholder="Title"
                    className="input"
                    value={AccountCode}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Type: </label>
                  <input
                    type="text"
                    name="acc_type"
                    placeholder="Account Type"
                    className="input"
                    value={AccountType}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Category: </label>
                  <input
                    type="text"
                    name="acc_cat"
                    placeholder="Account Category"
                    className="input"
                    value={AccountCat}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Account Title: </label>
                  <input
                    type="text"
                    name="acc_name"
                    placeholder="Title"
                    className="input"
                    value={acc_name}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Opening Balance: </label>
                  <input
                    type="number"
                    step="1"
                    name="opening_balance"
                    placeholder="Opening Balance"
                    className="input"
                    value={OpeningBal}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Description: </label>
                  <input
                    type="text"
                    name="acc_description"
                    placeholder="Description"
                    className="input"
                    value={acc_desc}
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <textarea
                    placeholder="Note "
                    id="noteTextarea"
                    value={acc_notes}
                    readOnly
                    rows="4"
                    className="textarea"
                  />
                </div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
          <label className="label">Last 50 Ledgers: </label>
          {promiseInProgress ? (
            <LoadingIndicator />
          ) : (
            <GridComponent
              className="custom-grid1"
              dataSource={CustLedgs}
              pageSettings={{ pageSize: 60 }}
              allowSorting
              allowResizing
              toolbar={["Search"]}
              rowHeight={18}
            >
              <ColumnsDirective>
                {ActsGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item} />
                ))}
              </ColumnsDirective>
              <Inject services={[Resize, Toolbar, Selection, Sort, Filter]} />
            </GridComponent>
          )}
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default ViewAccount;
