import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { GetSalesReturnDetailRecByID, GetSalesReturnDetail } from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";

const ViewSalesReturn = () => {
  const [AllProducts, setAllProducts] = useState("");
  const [customer, setcustomer] = useState(" ");
  const [so_id, setso_id] = useState(" ");
  const [project_name, setproject_name] = useState(" ");
  const [ship_method, setship_method] = useState(" ");
  const [tracking_no, settracking_no] = useState(" ");
  const [total_price, settotal_price] = useState(" ");
  const [amount_paid, setamount_paid] = useState(" ");
  // const [amount_pending, setamount_pending] = useState(" ");
  const [status, setstatus] = useState(" ");
  const [ret_date, setret_date] = useState(" ");
  const [user, setuser] = useState(" ");
  const [so_note, setso_note] = useState(" ");
  const [tax, settax] = useState(" ");

  let param = useParams();
  const navigate = useNavigate();

  const customerGridImage2 = (props) => (
    <div>{props.code + " - " + props.product_name}</div>
  );

  const customersGrid = [
    // {
    //   headerText: "Product Code",
    //   field: "code",
    //   width: "150",
    //   textAlign: "Center",
    // },
    // {
    //   headerText: "Product",
    //   field: "product_name",
    //   width: "180",
    //   textAlign: "left",
    // },
    {
      headerText: "Product",
      template: customerGridImage2,
      minWidth: "180",
      width: "220",
      maxWidth: "320",
      textAlign: "left",
    },
    {
      headerText: "Vendor",
      field: "vendor",
      width: "130",
      textAlign: "left",
    },
    {
      field: "quantity",
      headerText: "Qty",
      minWidth: "95",
      width: "95",
      maxWidth: "100",
      textAlign: "right",
    },
    {
      field: "unit_price",
      headerText: "Price",
      format: "C2",
      width: "110",
      textAlign: "right",
    },
    {
      field: "discount",
      headerText: "Discount",
      width: "130",
      format: "C2",
      textAlign: "right",
    },
    {
      field: "total",
      headerText: "Total",
      format: "C2",
      width: "120",
      textAlign: "right",
    },
    {
      field: "item_note",
      headerText: "Notes",
      width: "200",
      textAlign: "left",
    },
    {
      field: "quantity_shipped",
      headerText: "Qty Ship'd",
      width: "135",
      textAlign: "right",
    },
    {
      field: "return_qty",
      headerText: "Qty Returned",
      width: "145",
      textAlign: "right",
    },
    {
      field: "return_note",
      headerText: "Return Notes",
      width: "200",
      textAlign: "left",
    },
  ];

  const { currentColor, userID, userToken } = useStateContext();

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Back");
      navigate("/Sales");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (param.sr_id && userID && userToken) {
      TimeoutUtility.resetTimeout();
      const resp = GetSalesReturnDetailRecByID(param.sr_id, userID, userToken);
      resp
        .then(function (result) {
          setAllProducts(result.data);
        })
        .catch((err) => {
          console.log(err.message);
        });

      const resp1 = GetSalesReturnDetail(param.sr_id, userID, userToken);
      resp1
        .then(function (result) {
          setso_id(result.data[0].so_id);
          setcustomer(result.data[0].customer);
          setproject_name(result.data[0].project_name);
          setship_method(result.data[0].ship_method);
          settracking_no(result.data[0].tracking_no);
          settotal_price(result.data[0].total_price);
          setamount_paid(result.data[0].amount_paid);
          // setamount_pending(result.data[0].amount_pending);
          setstatus(result.data[0].status);
          setret_date(result.data[0].return_date);
          setuser(result.data[0].user);
          setso_note(result.data[0].sr_note);
          settax(result.data[0].tax);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [param.sr_id, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="VIEW SALES RETURN" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">SR#:</label>
                  <input
                    required
                    value={param.sr_id}
                    type="text"
                    name="sr_id"
                    placeholder="Customer PO NO"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">SO#:</label>
                  <input
                    required
                    value={so_id}
                    type="text"
                    name="so_id"
                    placeholder="Customer PO NO"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Customer: </label>
                  <input
                    required
                    type="text"
                    name="customer"
                    value={customer}
                    placeholder="Customer"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Contact:</label>
                  <input
                    type="text"
                    name="phone"
                    value={project_name}
                    placeholder="Contact"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Ship Method:</label>
                  <input
                    type="text"
                    name="ship_ment"
                    placeholder="Ship Method"
                    className="input"
                    value={ship_method}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Tracking No:</label>
                  <input
                    type="text"
                    name="city"
                    value={tracking_no}
                    placeholder="Tracking no."
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Total Price:</label>
                  <input
                    type="text"
                    name="state"
                    value={total_price}
                    placeholder="Total"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Amount Paid:</label>
                  <input
                    type="text"
                    name="postal_code"
                    value={amount_paid}
                    placeholder="Amount Paid"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              {/* <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Amount Pending: </label>
                  <input
                    placeholder="Amount Pending"
                    value={amount_pending}
                    className="input"
                    readOnly
                  />
                </div>
              </div> */}
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Tax %: </label>
                  <input
                    placeholder="Tax"
                    value={tax}
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Return Date: </label>
                  <input
                    placeholder="Return Date"
                    value={ret_date}
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Order Status: </label>
                  <input
                    placeholder="Order Status"
                    value={status}
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">User: </label>
                  <input
                    placeholder="User"
                    value={user}
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={12} className="container-col">
              <div className="col-lg-12 mt-2">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <textarea
                    placeholder="Note"
                    value={so_note}
                    id="noteTextarea"
                    className="textarea"
                    style={{ height: "40px", width: "95%" }}
                    readOnly
                  />
                </div>
              </div>
              <br />
            </Col>
          </Row>
          <GridComponent
            dataSource={AllProducts}
            allowPaging={true}
            pageSettings={{ pageSize: 10 }}
            allowSorting
            //toolbar={["Search"]}
            allowResizing
            className="custom-grid"
            rowHeight={36}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Page, Resize, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default ViewSalesReturn;
